import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import ReactGA from 'react-ga4'
import { schema } from 'schemas/addFundsSummarySchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { seoTitleTemplate, Mixpanel, Braze, Fullstory, removeBuyOrderRedirect } from 'utils'
import { PageLoading } from 'components'
import { getAddFundsSummaryPage, getAddFundsACHSummaryPage, commitAddFunds } from 'slices/walletSlice'
import SummaryDetails from './partials/SummaryDetails'
import SummaryForm from './partials/SummaryForm'
import SeoMeta from 'components/Global/SeoMeta'
import Breadcrumb from 'components/Global/Breadcrumb'
import TransferErrorModal from './partials/TransferErrorModal'
import SummaryDetailsPlaid from './partials/SummaryDetailsPlaid'
import SummaryDetailsPlaidError from './partials/SummaryDetailsPlaidError'
import parseErrorMessages from 'actions/parsing/parseErrorMessages'
import { addFundsErrorType } from 'types/errorTypes'
import { useQuery } from 'hooks/useQuery'

const FBOAddFundsSummary = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const { featureFlags } = useSelector((state) => state.userSlice)
  const { InstantAchFundingEnabled } = featureFlags || {}
  const [upholdAPIError, setUpholdAPIError] = useState('')
  const [achError, setAchError] = useState(null)
  const amount = query.get('amount')
  const cardCurrency = query.get('cardCurrency')
  const cardLabel = query.get('cardLabel')
  const type = query.get('type')
  const entityId = query.get('entityId')

  const items = [
    { name: 'My Portfolio', route: '/portfolio' },
    { name: 'Cash Account', route: `/cash-account${entityId ? `?entityId=${entityId}` : ''}` }
  ]

  const { pageLoading, otpRequired, transactionId, msToLive } = useSelector(
    (state) => state.walletSlice
  )
  const [showTansferErrorModal, setShowTansferErrorModal] = useState(false)
  const [showOtpRequired, setShowOtpRequired] = useState(false)

  const cardId = location.pathname.split('/cash-account/add-funds-summary/')[1]

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    context: {
      otpRequired: type === 'Uphold' && (otpRequired || showOtpRequired)
    }
  })

  const refreshGetAddFundsSummary = () => {
    if (type === 'Bank Account') {
      dispatch(
        getAddFundsACHSummaryPage({ amount, cashExternalAccountId: cardId })
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('View Add Funds Summary Page')
        } else {
          Mixpanel.track('Funding Error', { 'Error Type': payload })
          Braze.track('Funding Error', { 'Error Type': payload })
        }
      })
    } else if (type === 'Uphold') {
      dispatch(
        getAddFundsSummaryPage({ amount, cardCurrency, cardId, cardLabel })
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('View Add Funds Summary Page')
          if (payload.insufficientFunds) {
            Mixpanel.track('Funding Error', { 'Error Type': 'UPHOLD_INSUFFICIENT_FUNDS' })
            Braze.track('Funding Error', { 'Error Type': 'UPHOLD_INSUFFICIENT_FUNDS' })
            history.push({
              pathname: '/cash-account/add-funds',
              state: { amount, cardId, error: 'UPHOLD_INSUFFICIENT_FUNDS' }
            })
          }
        } else {
          if (payload) {
            Mixpanel.track('Funding Error', { 'Error Type': payload })
            Braze.track('Funding Error', { 'Error Type': payload })
            handleCommitError(payload)
          }
        }
      })
    }
  }

  useEffect(() => {
    refreshGetAddFundsSummary()
  }, [])

  useEffect(() => {
    let timerId
    if (msToLive) {
      timerId = setTimeout(() => {
        refreshGetAddFundsSummary()
      }, msToLive)
    }
    return () => {
      clearTimeout(timerId)
    }
  }, [transactionId])

  const handleCommitError = (AddFundsError) => {
    switch (AddFundsError) {
    case addFundsErrorType.UPHOLD_OTP_REQUIRED:
      setShowOtpRequired(true)
      methods.setError('upholdOTP', {
        type: addFundsErrorType.UPHOLD_OTP_REQUIRED,
        message:
            'Please enter the six-eight digit one-time passcode from your Authenticator App for your Uphold account and try again.'
      })
      break
    case addFundsErrorType.UPHOLD_OTP_INCORRECT:
      setShowOtpRequired(true)
      methods.setError('upholdOTP', {
        type: addFundsErrorType.UPHOLD_OTP_INCORRECT,
        message:
            'Incorrect code. Please enter the six-eight digit one-time passcode from your Authenticator App for your Uphold account and try again.'
      })
      break
    case addFundsErrorType.UPHOLD_INSUFFICIENT_FUNDS:
      history.push({
        pathname: '/cash-account/add-funds',
        state: { amount, cardId, error: addFundsErrorType.UPHOLD_INSUFFICIENT_FUNDS }
      })
      break
    case addFundsErrorType.ADD_FUNDS_HIGH_RISK_DETECTED:
      history.push('/cash-account/add-funds')
      break
    default:
      setUpholdAPIError(AddFundsError)
      setShowTansferErrorModal(true)
    }
  }

  const cancelAddFunds = () => {
    Mixpanel.track('Click Back on Add Funds Summary Page')
    // Set path for amount and entityid if they exist
    let path = ''
    if (amount && entityId) {
      path = `?entityId=${entityId}&amountRequired=${amount}`
    } else if (amount) {
      path = `?amountRequired=${amount}`
    } else if (entityId) {
      path = `?entityId=${entityId}`
    }
    history.push(`/cash-account/add-funds${path}`)
  }

  const onSubmit = async (formValues) => {
    Mixpanel.track('Click Confirm on Add Funds Summary Page')
    const commitRequest = {}
    if (type) {
      if (type === 'Uphold') {
        commitRequest.transactionId = transactionId
        if (formValues?.upholdOTP) {
          commitRequest.otpCode = formValues.upholdOTP
        }
      } else if (type === 'Bank Account') {
        commitRequest.amount = amount
        commitRequest.cashExternalAccountId = cardId
      }
      dispatch(commitAddFunds({ commitRequest, type })).then(({ meta, payload }) => {
        const path = localStorage?.getItem('from-buy-order-page')
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('Funding Successful', { Currency: cardCurrency, Amount: amount, 'Funding Method': type })
          Fullstory.track('Funding Successful', { currency: cardCurrency, amount: amount, funding_method: type })
          ReactGA.event('funding_successful', { 
            action: 'mf_funded_account',
            label: `Funding method: ${type}`,
            value: amount
          })
          // If instant ACH funding is enabled and buy order redirect exists, go back to buy order page
          if (InstantAchFundingEnabled && path && payload.balanceType !== 'PENDING') {
            history.push(path)
          } else {
            localStorage?.setItem('add-funds', `${type} - ${payload.balanceType}`)
            history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
          }
        } else {
          Mixpanel.track('Funding Error', { 'Error Type': payload })
          Braze.track('Funding Error', { 'Error Type': payload })
          if (payload === 'ADD_FUNDS_TOO_MANY_PENDING_DEPOSITS') {
            // If redirect exists and too many pending deposits remove redirect
            removeBuyOrderRedirect()
            setAchError('You cannot make another ACH deposit until your previous deposits have settled.')
          } else {
            handleCommitError(payload)
            setUpholdAPIError(payload)
          }
        }
      })
    }
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Add Funds Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Add Funds Summary')} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          <Breadcrumb items={items} />
          <h1>Add Funds Summary</h1>
          <div className='add-funds-container'>
            {type === 'Uphold' && <SummaryDetails />}
            {type === 'Bank Account' &&
            <>
              <SummaryDetailsPlaid />
              {achError && <SummaryDetailsPlaidError error={achError} />}
            </>}
            <FormProvider {...methods}>
              <SummaryForm
                onSubmit={onSubmit}
                cancelAddFunds={cancelAddFunds}
                showOtpRequired={showOtpRequired}
                type={type}
                hasError={achError}
              />
            </FormProvider>
          </div>
        </div>
        {showTansferErrorModal && (
          <TransferErrorModal
            hideModal={() => setShowTansferErrorModal(false)}
            message={parseErrorMessages({ error: upholdAPIError })?.placeOrderError?.message || ''}
            handleClick={() => {
              setShowTansferErrorModal(false)
              history.push(`/cash-account${entityId ? `?entityId=${entityId}` : ''}`)
            }}
            header={parseErrorMessages({ error: upholdAPIError })?.placeOrderError?.title || ''}
          />
        )}
      </div>
    </>
  )
}
export default FBOAddFundsSummary
