import { Mixpanel } from 'utils'
import NavigationLink from 'components/Nav/partials/NavigationLink'
import PropTypes from 'prop-types'

const FooterSection = ({ title, items }) => (
  <div className='flex flex-col'>
    <h3 className='mt-5 mb-4 medium_4'>{title}</h3>
    {items?.map((item, index) => (
      <NavigationLink
        key={`${item.fields?.title}-${index}`}
        item={item}
        onClick={() => {
          Mixpanel.track('Click Footer Link', { 'Footer Link': item?.fields?.title })
          if ( item?.fields?.title  === 'Invest') {
            localStorage?.removeItem('investFeedScroll')
          }
        }}
        onLogout={() => {}}
        className='medium_1 text-gray2 hover:text-white'
      />
    ))}
  </div>
)

FooterSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        title: PropTypes.string,
        linkUrl: PropTypes.string
      })
    })
  )
}

const FooterLinks = ({ footerEntry }) => {
  if (!footerEntry) {
    return null
  }
  const sections = [
    {
      title: footerEntry.footerLinksSectionTitle,
      items: footerEntry.footerLinksSection
    },
    {
      title: footerEntry.footerLinksSectionTitle2,
      items: footerEntry.footerLinksSectionMenuItems2
    },
    {
      title: footerEntry.footerLinksSectionTitle3,
      items: footerEntry.footerLinksSectionMenuItems3
    },
    {
      title: footerEntry.footerLinksSectionTitle4,
      items: footerEntry.footerLinksSectionMenuItems4
    }
  ].filter(section => section.items?.length > 0)

  // Split sections into two columns
  const midPoint = Math.ceil(sections.length / 2)
  const leftColumns = sections.slice(0, midPoint)
  const rightColumns = sections.slice(midPoint)
  
  return (
    <div className='flex justify-center mt-10 md:justify-between md:mt-14'>
      {/* Left Column */}
      <div className='flex-col w-1/2'>
        {leftColumns.map((section, index) => (
          <FooterSection
            key={`left-section-${index}`}
            title={section.title}
            items={section.items}
          />
        ))}
      </div>
      {/* Right Column */}
      <div className='flex-col w-1/2'>
        {rightColumns.map((section, index) => (
          <FooterSection
            key={`right-section-${index}`}
            title={section.title}
            items={section.items}
          />
        ))}
      </div>
    </div>
  )
}

FooterLinks.propTypes = {
  footerEntry: PropTypes.shape({
    footerLinksSectionTitle: PropTypes.string,
    footerLinksSection: PropTypes.array,
    footerLinksSectionTitle2: PropTypes.string,
    footerLinksSectionMenuItems2: PropTypes.array,
    footerLinksSectionTitle3: PropTypes.string,
    footerLinksSectionMenuItems3: PropTypes.array,
    footerLinksSectionTitle4: PropTypes.string,
    footerLinksSectionMenuItems4: PropTypes.array
  })
}

export default FooterLinks