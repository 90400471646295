import env, { ENV_BETA, ENV_DEV, ENV_PROD } from 'utils/env.js'

/**
 * Keys will be baked into the application as opposed to being added as a runtime config. That way, if the config resolution mechanism ever fails, we can still get monitoring alerts about it.
 */
export const apiKeys = {
  [ENV_PROD]: {
    key: 'haqFZ71rHUOTfI0ZZfiGQ',
  },
  [ENV_BETA]: {
    key: 'kNAEvhHYqkM5lliLUm1dg'
  },
  [ENV_DEV]: {
    key: 'EWrFPy0zzN5YFrwtoseQQ'
  }
}

export const isMonitoringEnabled = () => {
  return !env.isLocal()
}

export default {
  apiKeys,
  isMonitoringEnabled
}