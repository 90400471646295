function isToggleEnabled(toggleName) {
  const toggles = getToggles()
  return toggles[toggleName] === true
}

function getToggles() {
  const urlParams = new URLSearchParams(window.location.search)
  const togglesParam = urlParams.get('toggles')
  let toggles = {}
  if (togglesParam) {
    toggles = togglesParam.split(',').reduce((acc, toggle) => {
      const [key, value] = toggle.split(':')
      if (key && value) {
        acc[key] = value === 'true'
      } else if(key) {
        // assume true if not explicitly defined
        acc[key] = true
      }
      return acc
    }, {})
  }
  return toggles
}

export default {
  isToggleEnabled
}