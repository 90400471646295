import env, { ENV_BETA, ENV_DEV, ENV_PROD } from 'utils/env.js'

const ONE_MINUTE = 60 * 1000
const TWO_HOURS = 2 * 60 * 60 * 1000

const firebaseProjectConfig = {
  [ENV_PROD]: {
    apiKey: 'AIzaSyCSIlyRv24mRwdCUQU3V2MV4kUynWCy9wA',
    authDomain: 'linqto-prod-b5a7a.firebaseapp.com',
    projectId: 'linqto-prod-b5a7a',
    storageBucket: 'linqto-prod-b5a7a.firebasestorage.app',
    messagingSenderId: '219013518034',
    appId: '1:219013518034:web:561441efee33aab167530b',
    measurementId: 'G-DCFXHB2E9T'
  },
  [ENV_BETA]: {
    apiKey: 'AIzaSyC7IEU0JqCOX2K-dCLAnUus12O4Rxw4IXQ',
    authDomain: 'linqto-beta.firebaseapp.com',
    projectId: 'linqto-beta',
    storageBucket: 'linqto-beta.firebasestorage.app',
    messagingSenderId: '603351595287',
    appId: '1:603351595287:web:d922d680f55e468c32df52',
    measurementId: 'G-2PV3KRE2BT'
  },
  [ENV_DEV]: {
    apiKey: 'AIzaSyBgR_7SPmm2BDiqiplFS8qqT3KtdrjdQjM',
    authDomain: 'linqto-dev-2670a.firebaseapp.com',
    projectId: 'linqto-dev-2670a',
    storageBucket: 'linqto-dev-2670a.firebasestorage.app',
    messagingSenderId: '646288007260',
    appId: '1:646288007260:web:a64427f0918c1098c58699',
    measurementId: 'G-KV0PJK7MPL'
  }
}

export const getRemoteConfigSettings = () => {
  return {
    minimumFetchIntervalMillis: env.isProd() ? TWO_HOURS : ONE_MINUTE
  }
}

export const getProjectConfig = () => {
  const appEnv = env.getEnv()
  return firebaseProjectConfig[appEnv]
}

export default getProjectConfig
