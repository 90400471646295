import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Mixpanel, Fullstory } from 'utils'
import { images } from 'assets'
import NavigationLink from './NavigationLink'

const MobileNav = ({ 
  items, 
  logOut, 
  setToggleStatus, 
  customNavItem 
}) => {
  const { pathname } = useLocation()
  const [activeDropdown, setActiveDropdown] = useState('')

  // Handle document body overflow and app height on mount
  useEffect(() => {
    // Lock scroll when nav is open
    document.body.style.overflow = 'hidden'
    const updateAppHeight = () => {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    updateAppHeight()
    window.addEventListener('resize', updateAppHeight)

    return () => {
      document.body.style.overflow = ''
      window.removeEventListener('resize', updateAppHeight)
    }
  }, [])

  // Set active dropdown based on current route
  useEffect(() => {
    const findActiveDropdown = () => {
      for (const item of items) {
        const matchingChild = item?.children?.find(
          child => child?.fields?.linkUrl === pathname
        )
        if (matchingChild) {
          return item.fields.title
        }
      }
      return ''
    }
    
    setActiveDropdown(findActiveDropdown())
  }, [pathname, items])

  const trackNavClick = (item) => {
    Mixpanel.track('Click Main Nav', {'Main Nav Item': item?.fields?.title})
    Fullstory.track('Click Main Nav', {
      main_nav_item: item?.fields?.title,
      current_url: item?.fields?.linkUrl
    })
  }

  const handleItemClick = (item) => {
    if (item?.children?.length > 0) {
      if(activeDropdown === item?.fields?.title) {
        setActiveDropdown('')
      } else {
        setActiveDropdown(item?.fields?.title)
      }
    } else {
      trackNavClick(item)
      setToggleStatus(false)
    }
  }
  // Filter out sign out item for separate handling
  const navigationItems = items.filter(
    item => item.fields.title.toLowerCase() !== 'sign out'
  )
  const signOutItem = items.find(
    item => item?.fields?.title.toLowerCase() === 'sign out'
  )

  return (
    <nav className='main-nav mobile-active' aria-label='Mobile Navigation' data-testid='mobile-nav'>
      <div className='mobile-container'>
        {navigationItems.map((item) => {
          const isActive = activeDropdown === item?.children?.find(i => !!(i?.fields?.linkUrl === pathname))?.fields?.parentMenuItem || 
                           activeDropdown ===  item?.fields?.title
                           
          const hasChildren = item?.children?.length > 0
          
          return (
            <div key={item?.sys?.id} className={`animate-up ${isActive ? 'down' : 'up'}`}>
              {hasChildren ? (
                <button
                  onClick={() => handleItemClick(item)}
                  className='w-full'
                  aria-expanded={isActive}
                  aria-controls={`dropdown-${item.sys.id}`}
                >
                  <div className={`flex items-center justify-center mobile-nav-items ${isActive ? 'cobalt' : ''}`}>
                    {item.fields.title}
                    <img 
                      src={images['chevron-down']} 
                      height={20} 
                      width={20} 
                      alt=''
                      className={`transition-transform ${isActive ? 'rotate-180' : ''}`}
                    />
                  </div>
                </button>
              ) : (
                <NavigationLink
                  key={`mob-nav-${item?.sys?.id}`}
                  item={item}
                  onClick={handleItemClick}
                  onLogout={logOut}
                  className={`flex items-center justify-center mobile-nav-items ${isActive ? 'cobalt' : ''}`}
                />
              )}
              {hasChildren && (
                <div id={`dropdown-${item.sys.id}`} className={`transition-all ${isActive ? 'block' : 'hidden'}`}>
                  {item.children.map((subItem) => (
                    <NavigationLink
                      key={`nav-${subItem?.sys?.id}`}
                      item={subItem}
                      onClick={handleItemClick}
                      onLogout={logOut}
                      className='sub-nav-items'
                    />
                  ))}
                </div>
              )}
            </div>
          )
        })}
      </div>
      {customNavItem()}
      {signOutItem && (
        <button className='w-full mobile-nav-items sign-out-btn' onClick={logOut}>Sign Out</button>
      )}
    </nav>
  )
}

export default MobileNav