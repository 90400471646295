import { useDispatch, useSelector } from 'react-redux'
import { Controller, useFormContext } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import Select from 'components/Global/Inputs/Select'
import { getRegions } from 'slices/commonSlice'
import { useEffect } from 'react'

const AddressInput = ({
  section,
  category,
  sameAddress,
  disabled,
  countries,
  primaryRegions,
  mailingRegions,
  setMailingRegions,
  setPrimaryRegions,
  tooltip,
  control,
  getCountriesList,
  getRegionsList
}) => {
  const {
    setValue,
    clearErrors,
    watch
  } = useFormContext()

  const dispatch = useDispatch()
  const { hasKyc, initialValues } = useSelector((state) => state.profile)
  const currentCountry = watch(`${section}Country`)

  useEffect(() => {
    if (currentCountry && currentCountry !== '') {
      dispatch(getRegions(currentCountry)).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          if (category === 'Mailing') {
            setMailingRegions(payload)
          } else {
            setPrimaryRegions(payload)
          }
        }
      })
    }
  }, [currentCountry, category, dispatch, setMailingRegions, setPrimaryRegions])

  const onCountryChange = (val) => {
    if (category === 'Mailing') {
      setValue('mState', '', { shouldDirty: true })
    } else {
      clearErrors('usResidendcyStatus', 'NOT_REQUIRED')
      setValue('pState', '', { shouldDirty: true })
    }

    if (val === '') {
      if (category === 'Mailing') {
        setMailingRegions([])
      } else {
        setPrimaryRegions([])
      }
    }
  }

  return (
    <>
      {!sameAddress && (
        <>
          {category === 'Mailing' && (
            <div className='column sixteen'>
              <hr />
              <div className='large_1'>Mailing Addresss</div>
            </div>
          )}
          <div className='column eight sixteen-mobile'>
            <TextInput
              name={`${section}Street1`}
              label='Address Line 1'
              disabled={disabled}
              ariaLabel={`${section}Street1`}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name={`${section}Street2`}
              label='Address Line 2'
              disabled={disabled}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name={`${section}City`}
              label='City'
              disabled={disabled}
              ariaLabel={`${section}City`}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <TextInput
              name={`${section}Zip`}
              label='Postal Code'
              disabled={disabled}
              ariaLabel={`${section}Zip`}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name={`${section}Country`}
              render={({ field }) => (
                <Select
                  field={field}
                  label={
                    category === 'Primary' ? 'Country of Residence' : 'Country'
                  }
                  name={`${section}Country`}
                  onClick={getCountriesList}
                  options={countries.length > 0 ? countries : [initialValues[`${section}Country`]]}
                  ref={null}
                  disabled={disabled || ((hasKyc && initialValues.pCountry !== '') && category === 'Primary')}
                  content={category === 'Primary' ? tooltip.pCountry : ''}
                  defaultOption={{ value: '', label: 'Select Country' }}
                  handleChange={onCountryChange}
                  ariaLabel={`${section}Country`}
                />
              )}
            />
          </div>
          <div className='column eight sixteen-mobile'>
            <Controller
              control={control}
              name={`${section}State`}
              render={({ field }) => (
                <Select
                  field={field}
                  label='State or Province'
                  name={`${section}State`}
                  onClick={() => getRegionsList(watch(`${section}Country`), category)}
                  options = {
                    category === 'Mailing' 
                      ? (mailingRegions.length > 0 
                        ? mailingRegions 
                        : [initialValues.mState]) 
                      : (primaryRegions.length > 0 
                        ? primaryRegions 
                        : [initialValues.pState])
                  }
                  ref={null}
                  disabled={disabled || !currentCountry || currentCountry === ''}
                  defaultOption={{ value: '', label: 'Select Region' }}
                  ariaLabel={`${section}State`}
                />
              )}
            />
          </div>
        </>
      )}
    </>
  )
}

export default AddressInput
