import { Button } from 'components'
import PropTypes from 'prop-types'
import { formatDecimal, tagStyle, transformPromoFlag } from 'utils'

const renderThumbnailItem = ({ listType, item, hideTag }) => {
  switch (listType) {
    case 'products':
      return (
        <div className='w-full'>
          <img src={item?.alternateLogoUrl || item?.iconUrl} alt={`${item?.name} logo`} className='w-full h-[148px] md:h-auto object-cover aspect-[1.73] rounded-lg' />
          {item?.promoFlag && item?.promoFlag !== 'NONE' && item?.promoFlag !== hideTag &&
            <span className={`absolute border rounded-[4px] sm:top-6 sm:left-6 top-3 left-3 bg-white ${tagStyle(item?.promoFlag)} text-xs px-1 uppercase`}>{transformPromoFlag(item?.promoFlag)}</span>
          }
        </div>)

    case 'bundles':
      return (
        <div className='flex overflow-hidden'>
          {item?.companies && item?.companies.length > 0 ? item?.companies?.map(({ iconUrl, name }, index) => (
            <img key={index} className={`w-12 h-12 rounded-[50%] ${index !== 0 ? '-ml-3' : ''}`} src={iconUrl} alt={name} data-testid={index} />
          )) : null}
        </div>
      )
    default: return '<div />'
  }
}

/**
 * Thumbnail component
 * @function Thumbnail
 * To render the square company thumbnail
 * @property {string} listType - products or bundles
 * @property {Object} item - products or bundle list item
 * @property {Boolean} hideTag - boolean to hide promo tag
 * @property {Function} onItemClick - function to handle click event
 * @property {Number} index - index of the item
 * @property {String} section - section name (Featured or Bundles)
 */
export const Thumbnail = ({ listType, item, hideTag = '', onItemClick, index, section }) => (
  <Button data-testid={`thumb-${listType}-${index}`} className={`flex flex-col rounded-2xl ${listType === 'products' ? 'sm:border-gray6 sm:border sm:p-4 p-0' : 'border border-gray6 p-4'} relative w-full md:w-[31.3%] lg:w-[31.8%] xl:w-[23.1%] overflow-hidden items-start text-left`} onClick={() => onItemClick(item, section)} ariaLabel={`${item?.name || item?.title}, price: ${formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}`}>
    {renderThumbnailItem({ listType, item, hideTag })}
    <div className='flex w-full md:flex-col'>
      {listType === 'products' ? <div className='flex flex-col w-full'>
        <div className='w-full mt-2 overflow-hidden medium_2 whitespace-nowrap overflow-ellipsis'>{item?.name || item?.title}</div>
        <div className='w-full overflow-hidden text-sm body_1 whitespace-nowrap overflow-ellipsis'>{item?.vertical || item?.companies?.map(obj => obj.name).join(' • ')}</div>
        <div className='w-full md:mt-2 md:text-left md:ml-0'>{(item?.sharePrice || item?.price) ? <div className='medium_2'>{formatDecimal(item?.sharePrice) || formatDecimal(item?.price)} <span className='text-gray3 body_1'>/ Share Price</span> </div> : <div className='mb-1 medium_2 text-gray3'>N/A</div>}</div>
      </div> : <div className='flex justify-between w-full sm:flex-col'>
        <div>
          <div className='w-full mt-2 overflow-hidden text-lg medium_4 whitespace-nowrap overflow-ellipsis'>{item?.name || item?.title}</div>
          <div className='w-full overflow-hidden text-sm body_1 whitespace-nowrap overflow-ellipsis'>{item?.vertical || item?.companies?.map(obj => obj.name).join(' • ')}</div>
        </div>
        <div className='text-right sm:flex-col sm:mt-2 sm:text-left'>
          <span className='small_1 text-gray3'>Bundle Price</span>
          <div>{(item?.sharePrice || item?.price) ? <div className='medium_2'>{formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}</div> : <div className='medium_2 text-gray3'>N/A</div>}</div>
        </div>
      </div>}
    </div>
  </Button>
)

Thumbnail.propTypes = {
  listType: PropTypes.oneOf(['products', 'bundles']),
  item: PropTypes.object,
  hideTag: PropTypes.string,
  onItemClick: PropTypes.func,
  index: PropTypes.number,
  section: PropTypes.string
}

/**
 * ListItem component
 * @function ListItem
 * To render the list of products
 * @property {Object} item - products list item
 * @property {Function} onItemClick - function to handle click event
 * @property {Number} index - index of the item
 * @property {String} section - title name (Featured, Bundles, Comapnies, etc)
 */
export const ListItem = ({ item, onItemClick, index, section }) => (
  <Button data-testid={`list-products-${index}`} className='flex border rounded-2xl border-gray6 p-4 gap-4 w-full md:w-[48.5%] lg:w-[48.7%] items-center overflow-x-hidden' onClick={() => onItemClick(item, section)} ariaLabel={`${item?.name || item?.title}, price: ${formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}`}>
    <img src={item?.iconUrl} alt={`${item?.name} logo`} className='w-[48px] h-[48px] rounded-[50%]' />
    <div className='flex flex-col items-start'>
      <div className='text-left body_2 '>{item?.name}</div>
      <div className='text-left body_1'>{item?.vertical}</div>
    </div>
    <div className='ml-auto text-right'><div className='text-gray3 small_1'>Share Price</div>{item?.sharePrice ? <div className='medium_2'>{formatDecimal(item?.sharePrice)}</div> : <div className='medium_2 text-gray3'>N/A</div>}</div>
  </Button>
)

ListItem.propTypes = {
  item: PropTypes.object,
  onItemClick: PropTypes.func,
  index: PropTypes.number,
  section: PropTypes.string
}