import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ConfigProvider } from './config/ConfigContext.jsx'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { App, GoogleRecaptcha } from 'components'

const AppWrapper = ({ store, history, googleClientId }) => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <ConfigProvider>
          <GoogleOAuthProvider clientId={googleClientId}>
            <GoogleRecaptcha>
              <App />
            </GoogleRecaptcha>
          </GoogleOAuthProvider>
        </ConfigProvider>
      </Router>
    </Provider>
  )
}

export default AppWrapper
