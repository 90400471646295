import { PageLoading } from 'components'
import { transformPromoFlag, tagStyle } from 'utils'
import api from '../../apis/private'
import { useQuery } from '@tanstack/react-query'
import { CartesianGrid, XAxis, YAxis, Tooltip, AreaChart, Area, ReferenceLine, ResponsiveContainer } from 'recharts'

const getInvestDetail = async (company) => {
  const response = await api.get(`/page/invest/${company}`)
  return response.data
}

const ChartTooltip = ({ payload, active }) => {
  const data = payload?.[0]?.payload || {}
  
  if (active && !!data) {
    return (
      <div className='shadow rounded-lg p-4 flex-col w-[250px] bg-white'>
        <span className='small_2'>Series B</span>
        <span className='small_1'>03/31/2023</span>
        <div className='flex items-center justify-between my-2'>
          <span className='small_1 text-text-default-secondary'>Raised</span>
          <span className='small_1'>$421M</span>
        </div>
        <div className='flex-col'>
          <span className='mb-1 small_1 text-text-default-secondary'>Key Investors</span>
          <span className='small_1'>Nvidia, Magnetar Capital, Nat Friedman, Daniel Gross</span>
        </div>
      </div>
    );
  }

  return null;
}

const InvestDetailRedesign = ({ match }) => {
  const { data, isLoading } = useQuery({ queryKey: ['products', match.params.id], queryFn: () => getInvestDetail(match.params.id) })

  const dataFormatter = (number) => {
    let num = ''
    if(number > 1000000000){
      num = (number/1000000000).toString() + 'B';
    }else if(number > 1000000){
      num = (number/1000000).toString() + 'M';
    }else if(number > 1000){
      num = (number/1000).toString() + 'K';
    }else {
      num = number.toString()
    }
    return `$${num}`
  }

  if (isLoading) {
    return <PageLoading />
  }

  const { company } = data || {}
  const { iconUrl, name, description, companyDetail, promoFlag } = company || {}
  const { founded, website, headquarters, valuationOverTime } = companyDetail || {}
  const { city, state } = headquarters || {}

  return (
    <div className='lg:px-[150px] md:px-[60px] px-6 m-auto'>
      <div className='sm:max-w-[60%] w-full'>
        <div className='items-center mt-8 sm:flex'>
          <img src={iconUrl} alt='companyIconUrl' className='mr-4 rounded-full h-14 w-14' />
          <h1 className='giant_2'>{name}</h1>
        </div>
        {promoFlag && <div className='my-4'>
          <span className={`border rounded-[4px] ${tagStyle(promoFlag)} text-xs px-1 uppercase`}>{transformPromoFlag(promoFlag)}</span>
        </div>}
        <div>
          <h1 className='medium_4 sm:hidden'>Overview</h1>
          <span className='medium_1'>{description}</span>
          <div className='py-4 mb-4 border-b md:py-8 border-gray2'>
            <span className='hidden mr-6 medium_1 md:inline-block'>{website}</span>
            <span className='hidden mr-6 medium_1 md:inline-block'>Founded {founded}</span>
            <span className='hidden mr-6 medium_1 md:inline-block'>{city}, {state}</span>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-2 pt-4 pb-8 sm:grid-cols-3 sm:gap-6'>
          <div className='flex-col'>
            <span className='medium_1 text-gray3'>Post-Money Valuation</span>
            <span className='medium_2'>$19.0B</span>
          </div>
          <div className='flex-col'>
            <span className='medium_1 text-gray3'>Total Amount Raised</span>
            <span className='medium_2'>$19.0B</span>
          </div>
          <div className='flex-col'>
            <span className='medium_1 text-gray3'>Last Amount Raised</span>
            <span className='medium_2'>$19.0B</span>
          </div>
          <div className='flex-col'>
            <span className='medium_1 text-gray3'>Financial Traction</span>
            <span className='medium_2'>$19.0B</span>
          </div>
          <div className='flex-col'>
            <span className='medium_1 text-gray3'>Key Pair #1</span>
            <span className='medium_2'>$19.0B</span>
          </div>
          <div className='flex-col'>
            <span className='medium_1 text-gray3'>Key Pair #2</span>
            <span className='medium_2'>$19.0B</span>
          </div>
        </div>
        <div className='p-4 my-4 border rounded-2xl border-l-gray6'>
          <h1 className='ml-4 medium_4'>Post Money</h1>
          <h1 className='ml-4 medium_4'>$19.0B</h1>
          <ResponsiveContainer height={400}>
            <AreaChart data={valuationOverTime}>
              <XAxis dataKey='year' tickLine={false} tick={{ fill: '#757575' }} stroke='#fff' />
              <YAxis dataKey='amount' tickLine={false} tickFormatter={dataFormatter} tick={{ fill: '#757575' }} stroke='#fff' />
              <CartesianGrid />
              <Tooltip content={<ChartTooltip />} />
              <ReferenceLine x='Page C' stroke='blue' label='Min PAGE' />
              <Area type='monotone' dataKey='amount' stroke='#0064FF' fill='#0064FF1A' dot={{ stroke: '#0064FF', strokeWidth: 2, fill: '#0064FF' }} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}

export default InvestDetailRedesign
