import PropTypes from 'prop-types'

const PurchaseMessagingBanner = ({ children, color, isVisible }) => {
  
  /**
 * PurchaseMessagingBanner component
 * a wrapper banner that is used for displaying messages related to purchasing on invest detail page
 * props include color, isVisible, and children
 * children will be formatted in a row (ex. icon, text and tooltip icon)
 *  and the banner will have a background color and border color based on the color prop
 */

  let backgroundColor = ''
  let borderColor = ''

  switch (color) {
  case 'red':
    backgroundColor = 'bg-[rgba(225,43,86,0.05)]'
    borderColor = 'border-[#E12B56]'
    break
  case 'blue':
    backgroundColor = 'bg-[rgba(0,100,255,0.05)]'
    borderColor = 'border-connectedCobalt'
    break
  case 'green':
    backgroundColor = 'bg-lightMint'
    borderColor = 'border-freshMint'
    break
  }

  if (!isVisible) {
    return null
  }

  return (
    <div className={`px-4 mt-4 border rounded-lg ${backgroundColor} ${borderColor} py-3.5 flex justify-between max-w-[344px] items-start w-full`}>
      {children}
    </div>
  )
}

export default PurchaseMessagingBanner

PurchaseMessagingBanner.propTypes = {
  color: PropTypes.string.isRequired, // banner color
  isVisible: PropTypes.bool.isRequired, // conditionally renders the banner
  children: PropTypes.node // content inside the banner
}