import { useState, useCallback, useEffect } from 'react'
import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'
function calcCoords (blockRect, hoverRect, docWidth, isMobile) {
  const isLeft = blockRect?.right + hoverRect?.width > docWidth
  const coords = {}
  if (!isLeft) {
    coords.x = blockRect?.right - hoverRect?.width * 0.4
    coords.type = 'right'
  } else {
    coords.x = blockRect?.left - 5 - hoverRect?.width * 0.75
    coords.type = 'left'
  }
  coords.y = blockRect?.top - hoverRect?.height <= 20 ? blockRect?.top + 25 : blockRect?.top - hoverRect?.height
  if (isMobile) {
    coords.x = docWidth / 2 - hoverRect?.width / 2
  }
  return coords
}
const Tooltip = ({ header, content, setShowHover, showHover, isStorybook = false, top }) => {
  const [blockRect, setBlockRect] = useState()
  const [hoverRect, setHoverRect] = useState()
  const [coords, setCoords] = useState()
  const [docWidth, setDocWidth] = useState(window.innerWidth)
  const [scrollY, setScrollY] = useState(window.scrollY)
  const { width } = useWindowSize()

  const blockRef = useCallback((node) => {
    if (node) {
      setBlockRect(node.getBoundingClientRect())
    }
  }, [docWidth, scrollY])
  const hoverRef = useCallback((node) => {
    if (node) {
      setHoverRect(node.getBoundingClientRect())
    }
  }, [docWidth, scrollY])
  useEffect(() => {
    if (showHover) {
      const coords = calcCoords(blockRect, hoverRect, docWidth, width <= MOBILE_WIDTH_SIZE)
      // Only set coords if not in storybook env
      if (!isStorybook) {
        setCoords(coords)
      }
    }
    return () => setCoords({})
  }, [showHover, blockRect, hoverRect, docWidth])

  useEffect(() => {
    window.addEventListener('resize', () => setDocWidth(window.innerWidth))
    window.addEventListener('scroll', () => setScrollY(window.scrollY))
    return () => {
      window.removeEventListener('resize', () => setDocWidth(window.innerWidth))
      window.removeEventListener('scroll', () => setScrollY(window.scrollY))
    }
  }, [])
  return (
    <div className={`tooltip ${top || 'top-[3px]'}`} ref={blockRef} onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)}>
      <img src={images.tooltip} className='w-4 h-4 ml-2 cursor-pointer' alt='Tooltip'/>
      <div
        ref={hoverRef}
        className={`medium_1 tooltip-box box ${(!showHover || !coords) && 'invisible'}`}
        style={{
          left: coords && coords.x,
          top: coords && coords.y,
          textAlign: 'left'
        }}
      >
        <div className='flex mb-2 row'>
          <img src={images.tooltip} alt='Tooltip' className='w-4 h-4' />
          <span className='body_2 ml-2 mb-0.5'>{header}</span>
        </div>
        {content}
      </div>
    </div>
  )
}

export default Tooltip
