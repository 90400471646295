import { Mixpanel, Fullstory } from 'utils'
import NavigationLink from './NavigationLink'

const NavItem = ({ setToggleStatus, toggleStatus, item, logOut }) => {
  const clickMainNav = () => {
    if (item.name === 'Invest') {
      localStorage?.removeItem('investFeedScroll')
    }
    Mixpanel.track('Click Main Nav', { 'Main Nav Item': item.fields.title })
    Fullstory.track('Click Main Nav', {
      main_nav_item: item.fields.title,
      current_url: item.fields.linkUrl
    })
    setToggleStatus(false)
  }

  const isSignOut = item?.fields?.title.toLowerCase() === 'sign out' || false

  return (
    <NavigationLink
      item={item}
      onClick={clickMainNav}
      onLogout={logOut}
      className={isSignOut ? 'item dropdown red' : `item ${toggleStatus ? 'mobile' : '' }`}
    />
  )
}

export default NavItem