import { useEffect, useState } from 'react'
import { PageLoading, ConditionalRender, Button } from 'components'
import { Mixpanel, seoTitleTemplate, toCamelCase, formatDecimal, formatShares, Fullstory, useLDFlags } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderConfirmedPage, getTradeConfirmedPage } from 'slices/placeOrderSlice'
import { useQuery } from 'hooks/useQuery'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import OrderSuccess from './OrderSuccess'

const TopSection = ({ order }) => (
  <>
    <div className='buy-order-header'>
      <h1>Order Confirmed</h1>
      <div className='buy-order-company-name'>
        <img className='buy-order-company-icon' src={order?.company?.iconUrl} alt={order?.company?.name} />
        <div className='company-name'>{order?.company?.name}</div>
      </div>
    </div>
    <div className='order-confirmed-container'>
      <div className='order-confirmed-row'>
        <span>Order ID</span>
        <span>{order?.orderId}</span>
      </div>
      <div className='order-confirmed-row'>
        <span>Estimated Shares</span>
        <span>{formatShares(order?.shares)}</span>
      </div>
      <div className='order-confirmed-row'>
        <span className='medium_2'>Investment Amount</span>
        <span className='medium_2'>{formatDecimal(order?.amount)}</span>
      </div>
    </div>
    <div className='medium_1 centered dark'>Thank you for your purchase.</div>
  </>
)

const OrderConfirmed = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()
  const minimumInvestmentAmount = query.get('minimumInvestmentAmount')
  const type = toCamelCase(query.get('type'))
  const purchaseCredits = query.get('purchaseCredits')
  const tradeId = query.get('tradeId')
  const sharePrice = query.get('sharePrice')
  const { newOrderSuccessPage } = useLDFlags(['newOrderSuccessPage'])
  const { order, ordersList, sellOrdersList, amountToCashAccount } = useSelector(state => state.placeOrderSlice)
  const { buyOrderConfirmedContentCards } = useSelector(state => state.commonSlice)
  const { featureFlags } = useSelector(state => state.userSlice)
  const { RecurringInvestmentEnabled } = featureFlags || {}
  const { orderId } = order
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    (async () => {
      const orderId = match.params.id
      const res = await dispatch(tradeId ? getTradeConfirmedPage(tradeId) : getOrderConfirmedPage(orderId))
      setPageLoading(false)
      const { payload, meta } = res
      if (meta?.requestStatus === 'fulfilled') {
        // Send invest amount and cash used for purchase.
        const investAmount = tradeId ? payload?.ordersList?.[0]?.amount : payload?.order?.amount
        const cashUsed = res?.payload?.amountToCashAccount < 0 ? res?.payload?.amountToCashAccount * -1 : undefined
        Mixpanel.track('View Order Confirmed Page', {
          'Company Name': payload?.order?.company?.name,
          'Minimum investment amount': minimumInvestmentAmount,
          'Amount selected': investAmount,
          'Estimated shares': payload?.order?.shares,
          'Share Price': sharePrice,
          'Payment method': type,
          'Order ID': match?.params?.id,
          'Purchase Credits': purchaseCredits,
          'Shares Sold': payload?.sellOrdersList?.[0]?.shares,
          'Shares Sold Value': res?.payload?.sellOrdersList?.[0]?.amount,
          'Company Shares Sold': payload?.sellOrdersList?.[0]?.company?.name,
          'Cash Used': cashUsed
        })
        Fullstory.track('Page View', {
          page_name: tradeId ? 'Trade With Holdings Order Confirmed Page' : 'Order Confirmed Page',
          company_name: payload?.order?.company?.name,
          minimum_investment_amount: minimumInvestmentAmount,
          amount_selected: investAmount,
          estimated_shares: payload?.order?.shares,
          share_price: sharePrice,
          payment_method: type,
          order_id: match?.params?.id,
          purchase_credits: purchaseCredits,
          shares_sold: payload?.sellOrdersList?.[0]?.shares,
          shares_sold_value: res?.payload?.sellOrdersList?.[0]?.amount,
          company_shares_sold: payload?.sellOrdersList?.[0]?.company?.name,
          cash_used: cashUsed
        })
      }
    })()
  }, [tradeId, match.params.id])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
        <PageLoading />
      </>
    )
  }

  if (newOrderSuccessPage) {
    return (
      <OrderSuccess
        order={tradeId ? ordersList?.[0] : order}
        amountToCashAccount={tradeId ? amountToCashAccount : 0}
      />
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Order Confirmed')} />
      <div className='page-container buy-order-container'>
        <div className='inner-container'>
          {buyOrderConfirmedContentCards?.length ? (
            <div className='mb-4 -mt-5 messaging-carousel-container md:-mt-9 md:mb-6'>
              <MessagingCarousel data={buyOrderConfirmedContentCards} page='Buy Order Confirmation' />
            </div>) : null}
          {tradeId ? <TopSection order={ordersList?.[0]} /> : <TopSection order={order} />}
          <ConditionalRender isVisible={amountToCashAccount > 0}>
            <div className='order-confirmed-amount-container'>
              <div className='order-confirmed-row'>
                <span className='medium_2'>Amount to Cash Account</span>
                <span className='medium_2'>{formatDecimal(amountToCashAccount)}</span>
              </div>
              <div className='medium_1'>A remainder of funds not applied toward purchase will be returned to your cash account.</div>
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={!tradeId}>
            <div className={`flex ml-auto mr-auto mt-10 ${RecurringInvestmentEnabled ? 'flex-col max-w-[375px]' : 'max-w-2xl gap-4 '}`}>
              <Button
                mode='secondary'
                onClick={() => {
                  Mixpanel.track('Click Make Another Purchase on Buy Order Confirmation Page')
                  Fullstory.track('Button', { name: 'Make Another Purchase' })
                  history.push('/products')
                }}
              >Make Another Purchase</Button>
              <Button
                onClick={() => {
                  Mixpanel.track('Click View Details on Buy Order Confirmation Page')
                  Fullstory.track('Button', { name: 'View Buy Order Details' })
                  history.push(`/order/${orderId}`)
                }}
              >View Details</Button>
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={!!tradeId}>
            <div className={`flex ml-auto mr-auto mt-10 ${RecurringInvestmentEnabled ? 'flex-col max-w-[375px]' : 'max-w-2xl gap-4 '}`}>
              <Button
                mode='secondary'
                customClass={`${RecurringInvestmentEnabled ? 'full-width' : ''}`}
                onClick={() => {
                  Mixpanel.track('Click View Buy Order Details on Buy Order Confirmation Page')
                  Fullstory.track('Button', { name: 'View Buy Order Details' })
                  if (ordersList?.[0]?.orderId) {
                    history.push(`/order/${ordersList?.[0]?.orderId}`)
                  }
                }}
              >View Buy Order Details</Button>
              <Button
                customClass={`${RecurringInvestmentEnabled ? 'full-width' : ''}`}
                onClick={() => {
                  Mixpanel.track('Click View Sell Order on Buy Order Confirmation Page')
                  Fullstory.track('Button', { name: 'View Sell Order Details' })
                  if (sellOrdersList?.[0]?.orderId) {
                    history.push(`/sell-order/${sellOrdersList?.[0]?.orderId}`)
                  }
                }}
              >View Sell Order</Button>
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={RecurringInvestmentEnabled}>
            <div className='centered'>
              <Link
                className='link connectedCobalt'
                onClick={() => {
                  Mixpanel.track('Schedule Recurring Investment Clicked')
                  Fullstory.track('Button', { name: 'Schedule Recurring Investment' })
                }}
                role='button'
                to={`/recurring-investment/?companyId=${order?.company?.companyId}`}
              >Schedule Recurring Investment</Link>
            </div>
          </ConditionalRender>
        </div>
      </div>
    </>
  )
}

export default OrderConfirmed
