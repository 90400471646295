import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import { useHistory } from 'react-router-dom'
import { seoTitleTemplate, Mixpanel, Braze, getDevice, Fullstory, useLDFlags } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import IDVerifyModal from './partials/IDVerifyModal'
import QRCodeModal from './partials/QRCodeModal'
import IdentityCTA from './partials/IdentityCTA'
import { getIdentity, getSignInQRCode, getPlaidIdentityToken, validatePlaidIdentity } from 'slices/kycSlice'
import { usePlaidLink } from 'react-plaid-link'

const Identity = () => {
  const { pageLoading, plaidIdentityToken, kycStatus } = useSelector((state) => state.kyc)
  const [showQRCodeModal, setShowQRCodeModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [plaidFail, setPlaidFail] = useState(false)
  const [currentEvt, setCurrentEvt] = useState(null)
  const { idv } = useLDFlags(['idv'])
  const dispatch = useDispatch()
  const history = useHistory()


  useEffect(() => {
    dispatch(getIdentity())
      .then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.setPeople({ 'KYC Complete': !!payload?.isKycVerified })
          Fullstory.setUserProperty({ is_kyc_complete: !!payload?.isKycVerified })
          if (payload?.isKycVerified) {
            history.push('/profile')
          } else {
            Mixpanel.track('View Profile - Complete Your Investor Profile')
          }
        }
      })
  }, [])

  useEffect(() => {
    if (idv === 'plaid') {
      dispatch(getPlaidIdentityToken())
    }
  }, [idv])

  const onSuccess = useCallback(async (publicToken, metadata) => {
    const plaidIdentityVerificationSessionId = metadata.link_session_id
    if (plaidIdentityVerificationSessionId) {
      dispatch(validatePlaidIdentity({ plaidIdentityVerificationSessionId })).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          if (payload.kycStatus === 'VERIFIED_AUTO') {
            Mixpanel.track('Onboarding Identity Verified')
            ReactGA.event('mf_kyc_confirmed', {
              category: 'Lifecycle Event',
              action: 'mf_kyc_confirmed',
              label: 'User confirmed KYC information',
              type: 'Plaid'
            })
            window.location.reload()
          }
          if (payload.kycStatus === 'FAILED') {
            if (!payload.canRetry) {
              setPlaidFail(true)
            } else {
              dispatch(getPlaidIdentityToken())
            }
          }
        }
        if (meta.requestStatus === 'rejected') {
          if (payload === 'UNDER_AGE_INVESTOR') {
            setPlaidFail(true)
          }
        }
      })
    }
  })

  const onExit = useCallback((error) => {
    if (error) {
      Mixpanel.track('Onboarding Error', { 'Error Type': error.error_code })
      Braze.track('Onboarding Error', { 'Error Type': error.error_code, Device: getDevice() })
    }
  }, [])

  const onEvent = useCallback(async (eventName, metadata) => {
    // log onEvent callbacks from Link
    // https://plaid.com/docs/link/web/#onevent
    // console.log(eventName, metadata)
    setCurrentEvt(eventName)
    if (eventName === 'IDENTITY_VERIFICATION_FAIL_SESSION') {
      Mixpanel.track('Onboarding Identity Verification Failed', { 'Fail Reason': metadata.error_code })
    } else {
      Mixpanel.track('Onboarding Plaid IDV Event', { 'Plaid IDV Event Name': eventName, 'IDV Step': metadata.view_name })
    }
  })
  useEffect(() => {
    if (plaidFail && currentEvt === 'IDENTITY_VERIFICATION_CLOSE_UI') {
      history.push('/identity/success-submission')
    }
  }, [plaidFail, currentEvt])
  // Plaid configuration
  const config = {
    token: plaidIdentityToken,
    onSuccess,
    onExit,
    onEvent
  }

  const { open } = usePlaidLink(config)

  const showQrCode = () => {
    setShowQRCodeModal(true)
    dispatch(getSignInQRCode()).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Onboarding QR Code')
      }
    })
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Complete Your Investor Profile')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Complete Your Investor Profile')} />
      <IdentityCTA
        showQrCode={showQrCode}
        setModal={setModal}
        modal={modal}
        openPlaid={open}
        idvFeatureValue={idv}
        kycStatus={kycStatus}
        plaidFail={plaidFail}
      />
      {showQRCodeModal && (
        <QRCodeModal hideModal={() => setShowQRCodeModal(false)} />
      )}
      {modal && <IDVerifyModal hideModal={() => setModal(false)} />}
    </>
  )
}

export default Identity
