import React from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'

const RegionCountryDropdown = ({ items, type, name, label, ariaLabel, disabled, onChange = () => {} }) => {
  const { register, formState: { errors } } = useFormContext()

  let placeholder = ''

  if (type === 'regions') {
    placeholder = 'Select Region'
  } else {
    placeholder = 'Select Country'
  }

  const options = items?.map((item, i) => (
    <option value={item} key={`${item} + ${i}`} data-testid='select-option'>{item}</option>
  ))

  return (
    <div className={`input-group ${(errors[name]) && 'error'}`}>
      <label>{label}</label>
      <select {...register(name, { onChange })} disabled={disabled} aria-label={ariaLabel}>
        <option value=''>{placeholder}</option>
        {options}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default RegionCountryDropdown
