export const CHART_COLORS = {
  stroke: '#DFDFDF',
  fill: '#0064ff',
  line: '#0064ff',
  text: '#717171',
  cursor: '#D1D5DB'
}
  
export const CHART_DIMENSIONS = {
  height: 340,
  dotRadius: 6,
  strokeWidth: 2
}
  
export const calculatePriceChange = (currentPrice, previousPrice) => {
	 if (!currentPrice || !previousPrice || previousPrice === 0) {
		 return {
			 value: 0,
			 percentage: '0.00'
		 }
  }
  
  const difference = currentPrice - previousPrice
  const percentage = ((difference / previousPrice) * 100).toFixed(2)
  if (isNaN(percentage)) {
	 return {
		 value: difference,
		 percentage: '0.00'
	 }
  }
  
  return {
    value: difference,
    percentage
  }
}
  
export const formatXAxis = (timestamp) => {
  if (!timestamp) return ''
  
  const date = new Date(timestamp)
  return date.toLocaleDateString('en-US', { 
    month: 'short',
    year: 'numeric'
  })
}
  
export const getThreePoints = (min, max) => {
  if (min === max) {
    // When min and max are the same, create a range around it using percentage
    const padding = min * 0.1
    const minPadding = Math.max(padding, 0.5)
    
    return [
      min - minPadding,
      min,
      min + minPadding
    ]
  }
  const baseMin = Math.floor(min)
  const maxPoint = Math.ceil(max)
  const range = maxPoint - baseMin
  return [
    baseMin,
    baseMin + range/2,
    maxPoint
  ]
}
  
export const generateQuarterTicks = (data, isMobile = false) => {
  if (!data?.length) return []

  const uniqueDates = Array.from(new Set(data.map(item => item.date))).sort((a, b) => a - b)
  
  if (isMobile) {
    return [uniqueDates[0], uniqueDates[uniqueDates.length - 1]]
  }
  
  const quarterStarts = new Set()
  data.forEach(item => {
    const date = new Date(item.date)
    const month = date.getMonth()
    const quarter = Math.floor(month / 3)
    
    if (month % 3 === 0) {
      const quarterStart = new Date(date)
      quarterStart.setMonth(quarter * 3)
      quarterStart.setDate(1)
      quarterStarts.add(quarterStart.getTime())
    }
  })
    
  return Array.from(quarterStarts).sort()
}