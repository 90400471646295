import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AppMonitor from 'utils/monitoring/AppMonitor.js'

function useAppMonitor() {
  let location = useLocation()
  useEffect(() => {
    AppMonitor.onPageView(location.pathname)
  }, [location])
}

export default useAppMonitor