import { useSelector } from 'react-redux'
import { formatDecimal, formatWholeNumber } from 'utils'
import { estimatedSharesCopy } from 'components/data'
import { useLDFlags } from 'utils/LaunchDarkly'

const InvestmentDetails = () => {
  const { inputPurchaseAmount } = useLDFlags(['inputPurchaseAmount'])
  const { order } = useSelector(
    (state) => state.placeOrderSlice
  )
  const { sharePrice, shares, amount } = order

  return (
    <div className='buy-order-section wih-margin top-aligned'>
      <div className='large_1 buy-order-section-title'>
        <span>1</span> Investment Details
      </div>
      <div className='buy-order-section-detail'>
        <div className='box invest-order-box'>
          <div className='buy-order-row'>
            <span>Price Per Share</span>
            <span>
              {formatDecimal(sharePrice)}
            </span>
          </div>
          <div className='buy-order-row spaced'>
            <span>Estimated Shares* {inputPurchaseAmount && <><br /> <p className='small_1 gray3'>Adjusted to reflect whole shares.</p></>}</span>
            <span>{formatWholeNumber(shares)}</span>
          </div>
          <div className='buy-order-row'>
            <span>Amount</span>
            <span className='medium_2'>{formatDecimal(amount)}</span>
          </div>
        </div>
        <div className='buy-order-copy'> *{estimatedSharesCopy} </div>
      </div>
    </div>
  )
}

export default InvestmentDetails
