import { getRoutesArray } from '../routes/config'
import { getDomain } from 'utils'

/**
 * Determines if a given href is an internal React route or external link
 * @param {string} href - The URL or path to check
 * @returns {Object} Object containing appLink boolean and processed href
 */
export const isReactLink = (href) => {
  // Early return if no href provided
  if (!href) {
    return { appLink: false, href: '/' }
  }

  // Get routes and remove home route
  const appRoutes = getRoutesArray().filter(path => path !== '/')

  // Check if href matches any app route
  const isInternalRoute = appRoutes.some(route => {
    // Remove trailing slashes for consistent comparison
    const normalizedRoute = route.replace(/\/$/, '')
    const normalizedHref = href.replace(/\/$/, '')
    return normalizedHref.startsWith(normalizedRoute)
  })

  // Return appropriate object based on route type
  return isInternalRoute
    ? { appLink: true, href }
    : { appLink: false, href: formatExternalUrl(href)}
}

/**
 * Formats external URLs ensuring proper protocol
 * @param {string} url - The URL to format
 * @returns {string} Properly formatted URL
 */
const formatExternalUrl = (url) => {
  if (!url) return ''
  
  if (url.startsWith('http')) {
    return url
  }
  
  return getDomain(url, true)
}