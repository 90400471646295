import { Mixpanel, Fullstory } from 'utils'
import { images } from 'assets'
import { Button } from 'components'
import NavigationLink from './NavigationLink'

const DropDownItem = ({ setShowDropdown, showDropdown, item, isActive, logOut }) => {
  const clickDropdownItem = (i) => {
    Mixpanel.track('Click Main Nav', { 'Main Nav Item': i?.fields?.title })
    Fullstory.track('Click Main Nav', {
      main_nav_item: i?.fields?.title,
      current_url: i?.fields?.linkUrl
    })
  }
  const id = item?.sys?.id
  const title = item?.fields?.title
  
  return (
    <Button
      key={`dropdown-${id}`}
      className={`item dropdown dropdown-6 animate-up ${isActive ? 'active' : ''}`}
      onMouseOver={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      aria-label={`Open ${title} menu`}
      aria-expanded={showDropdown}
    >
      <span className='dropdown-title'>{title}</span>
      <img src={images['chevron-down']} height={20} width={20} alt=''/>
      {showDropdown && (
        <div className='dropdown_menu dropdown_menu--animated dropdown_menu-6' id={`${id}-menu`}>
          {(item.children).map((i) => {
            return  (
              <NavigationLink
                key={`nav-${i?.sys?.id}`}
                item={i}
                onClick={clickDropdownItem(i)}
                onLogout={logOut}
                className='item dropdown medium_1'
                secondaryItem={i?.fields?.secondaryItem}
              />)
          })}
        </div>
      )}
    </Button>
  )
}

export default DropDownItem