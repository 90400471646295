import TextInput from 'components/Global/Inputs/TextInput'
import PasswordInput from 'components/Global/Inputs/PasswordInput'
import { useFormContext } from 'react-hook-form'
import Checkbox from 'components/Global/Inputs/Checkbox'
import Button from 'components/Global/Button'
import AccreditationOptions from './AccreditationOptions'
import SignupTerms from './SignupTerms'
import { useSelector } from 'react-redux'
import EmailInput from 'components/Global/Inputs/EmailInput'
import SignUpOAuthInfo from './SignUpOAuthInfo'
import SignInLink from './SignInLink'

const SignUpForm = ({
  onSubmit,
  isFromOrigination,
  setShowModal,
  submitLoading,
  oAuthToken
}) => {
  const { err, errMsg } = useSelector(
    (state) => state.userSlice
  )
  const { handleSubmit } = useFormContext()
  const showNormalError = err && errMsg !== 'REGISTRATION_EMAIL_ALREADY_ACTIVATED'
  return (
    <>
      <form className='grid-old' style={{marginTop: 0, marginBottom:0}} onSubmit={handleSubmit(onSubmit)} noValidate>
        {!oAuthToken &&
        <>
          <div className='column sixteen eight-mobile'>
            <TextInput
              name='firstName'
              label='First Name'
              ariaLabel='firstName'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen eight-mobile'>
            <TextInput
              name='lastName'
              label='Last Name'
              ariaLabel='lastName'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen'>
            <EmailInput
              name='email'
              label='Email'
              ariaLabel='email'
              disabled={submitLoading}
              id='signUpEmail'
            />
          </div>
          <div className='column sixteen'>
            <PasswordInput
              name='password'
              label='Password'
              ariaLabel='password-input'
              disabled={submitLoading}
              autoComplete='new-password'
            />
          </div>
        </>
        }
        {oAuthToken &&
          <SignUpOAuthInfo err={err} oAuthType={oAuthToken?.type} />
        }
        <div className='column sixteen'>
          <Checkbox
            label='I would like to receive updates on new investment opportunities and market insights.'
            name='marketingOptIn'
            ariaLabel='marketingOptIn'
            checkboxClass='marketing-checkbox'
          />
        </div>
        {isFromOrigination && (
          <AccreditationOptions setShowModal={setShowModal} />
        )}
        <div className='mt-2 column sixteen'>
          <SignupTerms isFromOrigination={isFromOrigination} setShowModal={setShowModal} />
        </div>
        {errMsg === 'REGISTRATION_EMAIL_ALREADY_ACTIVATED' && <div className='column sixteen text-error'>
          <span>An account already exists using this email address. Please <a className='medium_1' href='/signin'>sign in</a>.</span>
        </div>}
        {showNormalError && <div className='column sixteen text-error'>
          <span>Something went wrong when trying to retrieve your data from Apple/Google. Please <a className='medium_1' href='/contact' aria-label='contact us'>contact us</a> or try our normal <a className='medium_1' href='/signup' aria-label='sign up'>sign up</a>.</span>
        </div>}
        <div className='column sixteen'>
          <div className='flex'>
            <Button
              type='submit'
              loading={submitLoading}
              disabled={submitLoading}
              customClass='full-width'
            >
              {isFromOrigination ? 'Create Account' : err === 500 && oAuthToken ? 'Use Different Email' : 'Continue'}
            </Button>
          </div>
        </div>
        {!oAuthToken && 
          <>
            <div className='column sixteen'>
              <SignInLink />
              <div className='ml-auto mr-auto text-center mt-9 body_1 text-gray3'>For Accredited Investors only.</div>
            </div>
          </>}
      </form>
    </>
  )
}

export default SignUpForm
