import { useEffect, useMemo, useState } from 'react'
import 'rc-slider/assets/index.css'
import { ConditionalRender, Row, Tooltip, Button} from 'components'
import { images } from 'assets'
import { formatDecimal, formatWholeNumber, getSliderValue, Mixpanel, Fullstory, MOBILE_WIDTH_SIZE } from 'utils'
import LinqtoSlider from 'components/Global/LinqtoSlider'
import { useSelector } from 'react-redux'
import InvestDetailOrderPill from './InvestDetailOrderPill'
import { useLDFlags } from 'utils/LaunchDarkly'
import PurchaseMessagingBanner from './PurchaseMessagingBanner'
import useWindowSize from 'hooks/useWindowSize'
import { useHistory } from 'react-router-dom'
import TextInput from 'components/Global/Inputs/TextInput'
import { FormProvider, useForm } from 'react-hook-form'

const OrderDetails = ({ setAmount, setShares, shares, setSharePrice, sharePrice, totalSavings, setTotalSavings, setTotalTillNextTear, isPreferred, setIsPreferred, setTiers, name, discountPercent, setDiscountPercent, discountedAmount, setDiscountedAmount, handleButtonClick, buttonText }) => {
  const { details } = useSelector(state => state.investSlice)
  const { discountTiers, sliderIncrement, sliderMinimum, sliderMaxIndex, offers } = details || {}
  const { isProfileComplete, isAccredited } = details?.user || {}
  const [sliderIndex, setSliderIndex] = useState(0)
  const [showHover, setShowHover] = useState(false)
  const [showEstimatedSharesHover, setShowEstimatedSharesHover] = useState(false)
  const [showAccreditationHover, setShowAccreditationHover] = useState(false)
  const [amountUsd, setAmountUsd] = useState(sliderMinimum)
  const [selectedPurchaseIndex, setSelectedPurchaseIndex] = useState(0)
  const [quickBuyList, setQuickBuyList] = useState([])
  // Non formatted input value
  const [numberValue, setNumberValue] = useState(0)
  const { inputPurchaseAmount } = useLDFlags(['inputPurchaseAmount'])
  const { width } = useWindowSize()
  const history = useHistory()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const maxAmount = quickBuyList[sliderMaxIndex - 1]?.amount
  const methods = useForm({ mode: 'onTouched' })
  const { setValue } = methods
  const showMinimumBanner = numberValue < sliderMinimum
  const showMaxBanner = quickBuyList?.length > 0 ? numberValue > maxAmount : false
  // quick buy values are the minimum, 10k, and 25k
  const initialQuickBuyAmounts = [sliderMinimum, 10000, 25000]

  // Note - Initialize slider values on first render
  useEffect(() => {
    if (sliderIndex === 0) {
      updateSliderIndex(0)
    }
  }, [])

  useEffect(() => {
    // Parse quick buy buttons and input values
    if (inputPurchaseAmount && !isMobile) {
      if (selectedPurchaseIndex === 0 && sliderMinimum) {
        const investArr = [...Array(sliderMaxIndex).keys()]
        const investAmounts = investArr.map((index) => getSliderValue({ offers, sliderMinimum, sliderIncrement }, index))
        // Set quick buy options
        setQuickBuyList(investAmounts)
        setAmount(0)
        setValue('investmentAmount', formatDecimal(0))
        setNumberValue(0)
      }
    }
  }, [isMobile, inputPurchaseAmount, sliderMinimum, sliderMaxIndex, offers, sliderIncrement])

  const updateAmountSelected = (qbIndex) => {
    const qbAmount = initialQuickBuyAmounts[qbIndex - 1]
    // Match input amount to nearest quick buy option
    const nearsetAmount = quickBuyList.find(o => o.amount >= qbAmount) ?? quickBuyList[quickBuyList.length - 1]
    setAmount(nearsetAmount['amount'])
    setSelectedPurchaseIndex(qbIndex)
    setShares(nearsetAmount?.shares)
    setSharePrice(nearsetAmount['sharePrice'])
    // Update input
    setValue('investmentAmount', formatDecimal(qbAmount))
    setNumberValue(qbAmount)
  }

  const validatePriceValues = (inputVal) => {
    // Remove $ sign if present
    const numberValue = inputVal.charAt(0) === '$' ? inputVal.substring(1) : inputVal
    setNumberValue(numberValue)
    const formattedValue = `$${Number(numberValue) ? new Intl.NumberFormat('en-US').format(Number(numberValue).toFixed(2)) : '0.00'}`
    setValue('investmentAmount', formattedValue)
    // If amount is not a quick buy amount, then do not get lowest value
    if (numberValue && quickBuyList?.length > 0 && !initialQuickBuyAmounts.includes(Number(numberValue))) {
      // Get closest amount less than the entered input value
      const nearsetAmount = quickBuyList.reduce((prev, curr) => (curr.amount < numberValue && curr.amount > prev.amount) ? curr : prev)
      setAmount(nearsetAmount['amount'])
      setShares(nearsetAmount?.shares)
      setSharePrice(nearsetAmount['sharePrice'])
    }
    // If user inputs quick buy amount, button should appear selected
    if (initialQuickBuyAmounts.includes(Number(numberValue))) {
      updateAmountSelected(initialQuickBuyAmounts.indexOf((Number(numberValue))) + 1)
    } else setSelectedPurchaseIndex(undefined)
  }

  // This is called when someone drags the slider AND when someone clicks the Plus/Minus buttons
  const updateSliderIndex = (index, type = '') => {
    // If it is out of bounds, don't allow it
    if (index < 0 || index > sliderMaxIndex) {
      return
    }

    const sliderVal = getSliderValue({ offers, sliderMinimum, sliderIncrement }, index)
    if (type !== '') {
      Mixpanel.track('Click Slider', { Type: type, 'Company Name': name })
      Fullstory.track('Slider', { name: 'Purchase Order', company_name: name, type })
    }

    // Set the values
    setAmountUsd(sliderVal?.amount)
    setAmount(sliderVal?.amount)
    setSliderIndex(index)
    setShares(sliderVal?.shares)
    setSharePrice(sliderVal?.sharePrice)
    setTotalTillNextTear(sliderVal?.totalTillNextTier)
    setTotalSavings(sliderVal?.totalSavings)
    setIsPreferred(sliderVal?.isPreferred)
    setTiers(sliderVal?.tiers)

    const discount = discountPercent > 0 ? Math.floor((sliderVal?.amount * discountPercent) * 100) / 100 : 0
    setDiscountedAmount(discount)
    handleDiscountConversion(sliderVal)
  }

  const handleDiscountConversion = sliderVal => {
    const findCurrentDiscountTier = discountTiers?.filter((tier) => tier?.minimumPurchase < sliderVal?.amount)
    if (findCurrentDiscountTier && findCurrentDiscountTier.length === 1) {
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (findCurrentDiscountTier?.[0]?.percent) {
        setDiscountedAmount(Math.floor((sliderVal?.amount * findCurrentDiscountTier?.[0]?.percent) * 100) / 100)
        setDiscountPercent(findCurrentDiscountTier?.[0]?.percent)
      } else if (findCurrentDiscountTier?.[0]?.amount) {
        setDiscountedAmount(Math.floor(findCurrentDiscountTier[0]?.amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else if (findCurrentDiscountTier && findCurrentDiscountTier.length > 1) {
      const newDiscountTier = findCurrentDiscountTier[findCurrentDiscountTier.length - 1]
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (newDiscountTier?.percent) {
        setDiscountedAmount(Math.floor((sliderVal?.amount * newDiscountTier?.percent) * 100) / 100)
        setDiscountPercent(newDiscountTier?.percent)
      } else if (newDiscountTier?.amount) {
        setDiscountedAmount(Math.floor(newDiscountTier?.amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else {
      setDiscountedAmount(0)
      setDiscountPercent(0)
    }
  }

  let savingsCopy = ''

  if (isPreferred) {
    savingsCopy = <div className='invest-order-row__tier-price__copy-container'>
      <img src={images['team-apollo-black']} height={16} width={16} alt='Team Apollo Savings' />
      <span>Team Apollo Savings</span>
    </div>
  } else {
    savingsCopy = <span>Tiered Pricing Discount</span>
  }

  const plusBtnDisabled = sliderIndex >= sliderMaxIndex
  const minusBtnDisabled = sliderIndex <= 0

  // Check if the amount is positive
  const isAmountPositive = useMemo(() => +numberValue > 0, [numberValue])

  return (
    <div className={`box invest-detail-box invest-order-box ${inputPurchaseAmount ? 'w-[344px] p-5' : ''}`} style={{ marginTop: '16px' }}>
      <ConditionalRender isVisible={inputPurchaseAmount && !isMobile}>
        <div className='large_1 mb-[15px]'>Buy {details?.name}  
          <Tooltip
            top='top-0'
            header='Buy Shares'
            content='You are buying shares of a series of Linqto Liquidshares, LLC, a private unregistered fund, that invests directly or indirectly into this company.'
            showHover={showHover}
            setShowHover={setShowHover}
          /></div>
      </ConditionalRender>
      <Row className='invest-order-row amounts' style={{
        marginBottom: '14px'
      }}>
        <FormProvider {...methods}>
          {inputPurchaseAmount ? 
            (
              <>
                <span className='medium_1 mb-[6px]'>Investment Amount</span>
                <TextInput
                  className='w-[120px]'
                  name='investmentAmount'
                  ariaLabel='Investment Amount'
                  placeholder={formatDecimal(initialQuickBuyAmounts[selectedPurchaseIndex - 1])}
                  onBlur={(e) => {
                    validatePriceValues(e.target.value)
                  }}
                  handleKeyPress={(e) => {
                    const invalidEntry = (e.key !== '.' && Number.isNaN((Number(e.key)))) || e.key === null || e.key === ' ' || e.key === '-'
                    if (e.key === 'Enter') {
                      e.target.blur()
                    } else if (invalidEntry) {
                      e.preventDefault()
                    }
                  }}
                />
              </>
            )
            : (
              <>
                <span>Shares to Purchase</span>
                <span className='medium_2'>{formatWholeNumber(shares)}</span>
              </>
            )}
        </FormProvider>

      </Row>
      <ConditionalRender isVisible={inputPurchaseAmount && !isMobile}>
        <Row className='flex justify-between mt-[15px] mb-[25px]'>
          <InvestDetailOrderPill onClick={() => updateAmountSelected(1)} active={selectedPurchaseIndex === 1} amount={`$${sliderMinimum / 1000 + 'K'}`} />
          <InvestDetailOrderPill onClick={() => updateAmountSelected(2)} active={selectedPurchaseIndex === 2} amount='$10k' />
          <InvestDetailOrderPill onClick={() => updateAmountSelected(3)} active={selectedPurchaseIndex === 3} amount='$25k' />
        </Row>
        <div className='flex justify-between'>
          <p className='mb-5 medium_1'>Price Per Share</p>
          {/* Compare local share price to BE share price to show discount share pricing*/}
          {!isAmountPositive ? formatDecimal(0) : 
          formatDecimal(sharePrice) < formatDecimal(details?.sharePrice)
            ? (
              <div className='flex'>
                <div className='line-through medium_1 mr-1.5 gray3'>{formatDecimal(details?.sharePrice)}</div>
                <div className='medium_1'>{`${formatDecimal(sharePrice)}`}</div>
              </div>
            )
            : ( <div className='medium_1'>{`${formatDecimal(sharePrice)}`}</div>)
          }
        </div>
        <div className='mb-5 border-[.5px] border-gray1'/>
        <div className='flex justify-between'>
          <div className='mb-5 medium_1'>Estimated Shares
            <Tooltip
              header='Estimated Shares'
              content='Estimated shares adjusted to reflect whole shares and applied discounts.'
              showHover={showEstimatedSharesHover}
              setShowHover={setShowEstimatedSharesHover}
            />
          </div>
          <p className='medium_2'>{isAmountPositive ? formatWholeNumber(shares) : 0}</p>
        </div>
        <PurchaseMessagingBanner color='red' isVisible={showMinimumBanner && isAmountPositive}>
          <div className='flex'>
            <img src={images.exclaim} alt='exclaim' className='mr-2 ' />
            <p className='medium_1'>Minimum order is <span className='medium_2'>{formatDecimal(sliderMinimum)}</span></p>
          </div>
        </PurchaseMessagingBanner>
        <PurchaseMessagingBanner color='red' isVisible={showMaxBanner}>
          <div className='flex'>
            <img src={images.exclaim} alt='exclaim' className='mr-2 ' />
            <p className='medium_1'>Maximum order is <span className='medium_2'>{formatDecimal(maxAmount)}</span></p>
          </div>
        </PurchaseMessagingBanner>
        <PurchaseMessagingBanner color='blue' isVisible={!isAccredited || !isProfileComplete}>
          <div className='flex'>
            <img src={images.person} alt='person icon' className='mr-2 ' />
            {!isProfileComplete ? 
              <div className='flex'><div className='flex mr-0.5 body_1'>Complete your {' '}</div><Button onClick={() => history.push('/profile')} className='underline body_1'>investor profile.</Button></div>
              : <><div className='flex mr-0.5 body_1 '>Please {'  '} </div><Button onClick={() => history.push('/investor-status')} className='underline body_1'>verify your accreditation.</Button></>
            }
          </div>
          {(isProfileComplete && !isAccredited) &&
          <Tooltip
            top='top-0'
            header='Verify Accreditation'
            content='Regulations require you to be accredited to invest on Linqto.'
            showHover={showAccreditationHover}
            setShowHover={setShowAccreditationHover}
          />}
        </PurchaseMessagingBanner>
        <Button
          customClass='full-width'
          onClick={handleButtonClick}
          disabled={!isProfileComplete || !isAccredited || showMinimumBanner || showMaxBanner}
        >
          {buttonText}
        </Button>
      </ConditionalRender>
      <ConditionalRender isVisible={!inputPurchaseAmount}>
        <Row className='invest-order-row slider-row'>
          <LinqtoSlider
            min={0}
            max={sliderMaxIndex}
            step={1}
            defaultValue={0}
            onChange={(val) => updateSliderIndex(val)}
            onAfterChange={() => Mixpanel.track('Click Slider', { Type: 'Slider', 'Company Name': name })}
            value={sliderIndex}
            onMinusBtnClick={() => updateSliderIndex(sliderIndex - 1, 'Minus')}
            onPlusBtnClick={() => updateSliderIndex(sliderIndex + 1, 'Plus')}
            disabled={false}
            plusBtnDisabled={plusBtnDisabled}
            minusBtnDisabled={minusBtnDisabled}
          />
        </Row>
        <Row className='invest-order-row__tier-price'>
          <div className='invest-order-row__tier-price__left'>
            {savingsCopy}
          </div>
          <span className={`invest-order-row__tier-price__right ${totalSavings > 0 ? 'red' : ''}`}>{totalSavings > 0 && '-'}{formatDecimal(totalSavings)}</span>
        </Row>
        {(discountPercent > 0 || discountedAmount > 0) && <Row className='invest-order-row__tier-price'>
          <div className='invest-order-row__tier-price__left'>Promo Discount</div>
          <span className='invest-order-row__tier-price__right red'>
            {discountPercent > 0 && `-${formatDecimal(discountPercent * 100, false, 0)}% (${formatDecimal(discountedAmount)})`}
            {!discountPercent && discountedAmount > 0 && `-${formatDecimal(discountedAmount)}`}
          </span>
        </Row>}
        <Row className='invest-order-row amounts'>
          <span>Amount in USD</span>
          <span className='medium_2'>{formatDecimal(amountUsd)}</span>
        </Row>
      </ConditionalRender>
    </div>
  )
}

export default OrderDetails