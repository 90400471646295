import api from '../../../apis/private'

export const getChartData = async (companyUrlName) => {
  try {
    const [priceHistoryResponse, lastPriceChangeResponse] = await Promise.all([
      api.get(`/resource/price-history/${companyUrlName}`),
      api.get(`/resource/price-history/${companyUrlName}/last-change`)
    ])

    let priceHistory = priceHistoryResponse.data.priceHistory
    const lastPriceChange = lastPriceChangeResponse.data
    // adding this check because the API returns an empty array for comapnies added in the middle of the month, 
    // we need to wait till the end of the month to get the first price change (business logic)
    // so if there's no price history and we have a last price change, we should add it to the price history
    if ((!priceHistory || priceHistory.length === 0) && lastPriceChange && lastPriceChange.sharePrice > 0) {
      const date = new Date(lastPriceChange.date)
      priceHistory = [{
        date: date.getTime(),
        sharePrice: lastPriceChange.sharePrice,
        dateString: lastPriceChange.date,
        month: date.getMonth(),
        year: date.getFullYear()
      }]
    }

    return {
      priceHistory: priceHistory,
      lastPriceChange: lastPriceChange,
      companyName: priceHistoryResponse.data.companyName
    }
  } catch (error) {
    console.error('Error fetching chart data:', error)
    throw new Error('Failed to fetch chart data')
  }
}

export const getPlaceholderData = () => {
  return {
    priceHistoryData: {
      companyUrlName: 'company_name',
      companyName: 'company name',
      priceHistory: [
        {
          date: 1688194800000,
          price: 1,
          dateString: '2023-07-01T07:00:00Z',
          month: 6,
          year: 2023
        },
        {
          date: 1696143600000,
          price: 1,
          dateString: '2023-10-01T07:00:00Z',
          month: 9,
          year: 2023
        }
      ]
    },
    lastUpdateData: {
      lastPriceChange: {
        sharePrice: 1,
        date: '2025-01-01T08:00:00Z'
      }
    }
  }
}
