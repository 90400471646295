import FirebaseRmConfig from './firebase/FirebaseRemoteConfig.js'

/**
 * @interface
 * @typedef {Object} ConfigSource
 * @property {Function} fetchAndActivate
 * @property {Function} getValue
 */

/**
 *
 * @type {ConfigSource | null}
 */
let singleton = null

let configValues = {}

/**
 * The implementation and values are a singleton in the application
 *
 * @returns {Config}
 * @constructor
 */
function Config(implOverride = null) {
  if (implOverride) {
    singleton = implOverride
  }
  if (!singleton) {
    singleton = new FirebaseRmConfig()
  }
  return this
}

const CONFIG_TOKENS = [
  'FULLSTORY_ORG_ID',
  'MIXPANEL_ACCESS_TOKEN',
  'BRAZE_API_KEY',
  'BRAZE_BASE_URL',
  'LAUNCHDARKLY_CLIENT_SIDE_ID',
  'CONTENTFUL_ACCESS_TOKEN',
  'CONTENTFUL_SPACE',
  'CONTENTFUL_HOST'
]

Config.prototype.init = async function () {
  /**
   * https://firebase.google.com/docs/remote-config/get-started?platform=web#firebase-console_1
   * If you later set values in the backend, fetch them, and then activate them, those values are available to your app.
   */
  await singleton.fetchAndActivate()
  const parallel = CONFIG_TOKENS.map(token => {
    return new Promise((resolve, reject) => {
      singleton
        .getValue(token)
        .then(value => resolve(value))
        .catch(reject)
    })
  })

  const results = await Promise.all(parallel)
  const config = {}
  CONFIG_TOKENS.forEach((token, index) => {
    config[token] = results[index]
  })

  Object.assign(configValues, config)
  Object.assign(this, configValues)
  return this
}

export default Config

/**
 * For convenience if the desired config is not yet accounted for in the prototype
 * @param token
 * @returns {*|null}
 */
Config.prototype.get = function (token) {
  return this[token] || null
}
