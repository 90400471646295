import { Switch, Route } from 'react-router-dom'
import routesToArray from 'react-router-to-array' // this package can be removed once we upgraded our react router
import {
  NotFound,
  Contact,
  SignUp,
  SignIn,
  ActivateEmail,
  Verify,
  ConfirmResetPassword,
  OrderDetail,
  OrderConfirmation,
  OrderConfirmed,
  Portfolio,
  Invest,
  InvestRevamp,
  InvestDetail,
  PortfolioDetail,
  Profile,
  BrokerInfo,
  InvestorStatus,
  FinancialAdvisor,
  Identity,
  Documents,
  Security,
  AutoVerification,
  ManualVerification,
  AutoSignIn,
  Rewards,
  UnicornDetails,
  Origination,
  OriginationDetail,
  OriginationOffer,
  OriginationConfirmed,
  DeleteAccount,
  DeleteAccountSuccess,
  RIADashboard,
  SellOrder,
  SellOrderSummary,
  SellOrderDetail,
  RIATc,
  RIAUserAccreditation,
  LinqtoWallet,
  FBOAccount,
  FBOAddFunds,
  FBOAddFundsSummary,
  FBOWithdraw,
  FBOAddBank,
  SuccessSubmission,
  Home,
  Unsubscribe,
  PurchaseCredits,
  FinanciallySophisticatedTest,
  BundleOrder,
  BundleOrderConfirmed,
  RecurringInvestment,
  RecurringInvestmentConfirmed,
  SignUpFINRA,
  Redirect,
  InvestList,
  PageLoading,
  AiAuth,
  ExitIPO,
  AddEntity,
  InvestDetailRedesign,
  FinanciallySophisticatedResult,
  ReferSplash,
  SellOfferDetail
} from 'components'
import FormSuccess from '../components/Global/FormSuccess'
import MicroBlinkError from '../components/Kyc/AutoVerification/partials/MicroBlinkError'
import { useLDFlags } from 'utils'



export const RouteConfig = () => {
  const { investPageRedesign, investDetailPageRedesign } = useLDFlags(['investPageRedesign', 'investDetailPageRedesign'])
  return (
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/:name/success' component={FormSuccess} />
      <Route exact path='/products' component={investPageRedesign === true ? InvestRevamp : investPageRedesign === false ? Invest : PageLoading} />
      <Route exact path='/products/:id' component={investDetailPageRedesign ? InvestDetailRedesign : InvestDetail} />
      <Route exact path='/products-list' component={InvestList} />
      <Route exact path='/orderconfirmation/:id' component={OrderConfirmation}/>
      <Route exact path='/orderconfirmed/:id' component={OrderConfirmed} />
      <Route exact path='/bundle/:bundleId' component={BundleOrder} />
      <Route exact path='/bundle-confirmed/:tradeId' component={BundleOrderConfirmed}/>
      <Route exact path='/signup/create-account' component={SignUp} />
      <Route exact path='/signupfin' component={SignUpFINRA} />
      <Route exact path='/signup' component={SignUp} />
      <Route exact path='/signin' component={SignIn} />
      <Route exact path='/auto-sign-in' component={AutoSignIn} />
      <Route exact path='/signin/mfas' component={SignIn} />
      <Route exact path='/signin/mfas/:mfaType/:userMfaId' component={SignIn}/>
      <Route exact path='/activate' component={ActivateEmail} />
      <Route exact path='/verify' component={Verify}/>
      <Route exact path='/confirmResetPassword' render={() => <ConfirmResetPassword />}/>
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/order/:id' component={OrderDetail} />
      <Route exact path='/portfolio' component={Portfolio} />
      <Route exact path='/rewards' component={Rewards} />
      <Route exact path='/market-insights/:companyName' component={UnicornDetails} />
      <Route exact path='/portfolio/:companyId' component={PortfolioDetail}/>
      <Route exact path='/linqto-wallet' component={LinqtoWallet} />
      <Route exact path='/cash-account' component={FBOAccount} />
      <Route exact path='/cash-account/add-funds' component={FBOAddFunds} />
      <Route path='/cash-account/add-funds-summary' component={FBOAddFundsSummary} />
      <Route path='/cash-account/withdraw' component={FBOWithdraw} />
      <Route exact path='/cash-account/add-bank' component={FBOAddBank} />
      <Route exact path='/registered-investment-advisor' component={RIATc} />
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/investor-status' component={InvestorStatus} />
      <Route exact path='/financial-advisor' component={FinancialAdvisor} />
      <Route exact path='/exit-ipo/:companyId' component={ExitIPO} />
      <Route exact path='/identity/auto-verification/:steps' component={AutoVerification} />
      <Route exact path='/identity/fail-submission' component={MicroBlinkError} />
      <Route exact path='/identity/success-submission' component={SuccessSubmission} />
      <Route exact path='/identity' component={Identity} />
      <Route exact path='/identity/manual-verification' component={ManualVerification} />
      <Route exact path='/documents' component={Documents} />
      <Route exact path='/settings' component={Security} />
      <Route exact path='/settings/:mfaType/:userMfaId/:steps' component={Security} />
      <Route exact path='/settings/delete-account' component={DeleteAccount} />
      <Route exact path='/settings/delete-account-success' component={DeleteAccountSuccess}/>
      <Route path='/sell/offerconfirmation/' component={OriginationOffer} />
      <Route path='/sell/offerconfirmed/:originationId' component={OriginationConfirmed} />
      <Route path='/sell/:companyName' component={OriginationDetail} />
      <Route path='/sell' component={Origination} />
      <Route path='/broker-information/:entityId' component={BrokerInfo} />
      <Route path='/broker-information/' component={BrokerInfo} />
      <Route path='/ria-dashboard/' component={RIADashboard} />
      <Route path='/offer/:id' component={SellOfferDetail} />
      <Route exact path='/sell-order/create/:id' component={SellOrder} />
      <Route exact path='/sell-order/review' component={SellOrderSummary} />
      <Route exact path='/sell-order/:id' component={SellOrderDetail} />
      <Route exact path='/ria/verify-client-accreditation/:userId' component={RIAUserAccreditation} />
      <Route exact path='/unsubscribe' component={Unsubscribe} />
      <Route exact path='/purchase-credits' component={PurchaseCredits} />
      <Route exact path='/investor-status/financial-sophisticated-quiz/result' component={FinanciallySophisticatedResult} />
      <Route exact path='/investor-status/financial-sophisticated-quiz' component={FinanciallySophisticatedTest} />
      <Route path='/entity/:entityId' component={AddEntity} />
      <Route path='/entity' component={AddEntity} />
      <Route path='/recurring-investment/:recurringEventId' component={RecurringInvestment} />
      <Route path='/recurring-investment/' component={RecurringInvestment} />
      <Route exact path='/recurring-investment-confirmed/:recurringEventId' component={RecurringInvestmentConfirmed} />
      <Route path='/refer-friend' component={ReferSplash} />
      <Route path='/redirect' component={Redirect} />
      <Route path='/ai-auth' component={AiAuth} />
      <Route path='*' component={NotFound} />
    </Switch>)
}

export const getRoutesArray = () => {
  const routeConfig = RouteConfig()
  return routesToArray(routeConfig)
}