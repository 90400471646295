import moment from 'moment'
import { CountUp } from 'use-count-up'

export const MOBILE_WIDTH_SIZE = 767
export const TABLET_WIDTH_SIZE = 1023

// String formatting
export const toCamelCase = (str) => {
  if (str) {
    const strArr = str.split(/\s+/g)
    for (let i = 0; i < strArr.length; i++) {
      if (strArr[i].length === 0) {
        continue
      }
      strArr[i] = strArr[i][0].toUpperCase().concat(
        strArr[i]
          .split('')
          .splice(1, strArr[i].length - 1)
          .join('')
          .toLowerCase()
      )
    }
    return strArr.join(' ')
  } else {
    return ''
  }
}

export const toLowerCase = (str) => {
  const arr = str.split('_')
  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    newArr.push(arr[i].toLowerCase())
  }
  return newArr.join(' ')
}

export const toUpperCase = (str) => {
  const arr = str.split(' ')
  const newArr = []
  for (let i = 0; i < arr.length; i++) {
    newArr.push(arr[i].toUpperCase())
  }
  return newArr.join('_')
}

export const toCamalCase = (str) => {
  const strArr = str.split(/\s+/g)
  for (let i = 0; i < strArr.length; i++) {
    if (strArr[i].length === 0) {
      continue
    }
    strArr[i] = strArr[i][0].toUpperCase().concat(
      strArr[i]
        .split('')
        .splice(1, strArr[i].length - 1)
        .join('')
        .toLowerCase()
    )
  }
  return strArr.join(' ')
}

export const cleanSeriesName = (seriesName) => {
  if (!seriesName) return ''
  return seriesName.replace(/Linqto Liquidshares LLC - /, '').split(' - ')[1]
}

// Date formatting
export const formatDate = (timestamp) => {
  if (isNaN(timestamp)) return ''
  return moment(timestamp).format('MM/DD/YYYY')
}
export const formatDateNow = (timestamp) => {
  return moment(timestamp).fromNow()
}
// format to August 14 2024
export const formatDateMDY = (timestamp) => {
  return moment(timestamp).format('MMMM DD YYYY')
}

// Number and Decimal formatting
/**
 * Formats decimal value with optional formatting
 * @function formatDecimal
 * @param {*} value - value of the decimal
 * @param {*} showCurrency - shows the currency symbol
 * @param {*} decimaldigits - number of decimal digits
 * @param {*} stripCommas - removes commas from the value
 * @returns - formatted decimal value
 */
export const formatDecimal = (
  value,
  showCurrency = true,
  decimaldigits = 2,
  stripCommas = false
) => {
  if (!value && value !== 0) return ''
  if (value === 'Fully Subscribed') return 'Unavailable'
  const symbol = showCurrency ? '$' : ''

  let formattedValue = value.toLocaleString('en-US', {
    maximumFractionDigits: decimaldigits,
    minimumFractionDigits: decimaldigits
  })
  if (stripCommas) return formattedValue.replace(',', '')
  if (value < 0) {
    formattedValue = formattedValue.replace('-', '')
    return '-' + symbol + formattedValue
  } else {
    return symbol + formattedValue
  }
}

// format shares
export const formatShares = (value) => {
  const formattedFloatedValue = parseFloat(value)
  const formattedValue = formattedFloatedValue.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  })
  return formattedValue
}
// Whole Number formatting (Whole Shares)
export const formatWholeNumber = (value) => {
  if (!value && value !== 0) return ''
  const formattedValue = value.toLocaleString('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  })
  return formattedValue
}

// convert string to number (for transfer modal 'amount' interactions)
export const parseStringToFloat = (value) => {
  return parseFloat(value.replace(/,/g, ''))
}

// format user input into valid currency (USD)
export const currencyFormatter = (value) => {
  if (!value || value.match(/^[A-Za-z]+$/)) return ''
  const currentInput = parseStringToFloat(value)
  return currentInput.toLocaleString('en-US')
}

// Whole Number formatting with no decimals
export const formatNumberNoDeci = (value) => {
  if (!value && value !== 0) return ''
  const formattedValue = value.toLocaleString('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })
  return formattedValue
}

export const roundDecimal = (value, precision) => {
  const multiplier = 10 ** (precision || 0)
  return Math.round(value * multiplier) / multiplier
}

// Whole Number formatting with two decimals and K,M
export const formatNumberWithLetter = (
  value,
  showCurrency = false,
  showInKMB = false,
  decimaldigits = 0,
  removeDecimals = false
) => {
  let amount = value
  const isK = value >= 1000
  const isM = value >= 1000000
  const isB = value >= 1000000000
  const isT = value >= 1000000000000
  if (showInKMB) {
    if (isT) {
      amount = value / 1000000000000
    } else if (isB) {
      amount = value / 1000000000
    } else if (isM) {
      amount = value / 1000000
    } else if (isK) {
      amount = value / 1000
    } else {
      amount = value
    }
  }
  let formattedValue = amount?.toLocaleString('en-US', {
    maximumFractionDigits: decimaldigits,
    minimumFractionDigits: decimaldigits
  })
  if (removeDecimals) { formattedValue = roundDecimal(formattedValue, decimaldigits) }
  formattedValue = `${showCurrency ? '$' : ''}${formattedValue}${
    showInKMB ? (isT ? 'T' : isB ? 'B' : isM ? 'M' : isK ? 'K' : '') : ''
  }`
  return formattedValue
}

export const formatInvestmentValue = (
  value,
  showCurrency = false,
  showInKMB = false,
  decimalPlaces = 0
) => {
  let amount = value
  const isK = value >= 1000
  const isM = value >= 1000000
  const isB = value >= 1000000000
  const isT = value >= 1000000000000
  if (showInKMB) {
    if (isT) {
      amount = value / 1000000000000
    } else if (isB) {
      amount = value / 1000000000
    } else if (isM) {
      amount = value / 1000000
    } else if (isK) {
      amount = value / 1000
    } else {
      amount = value
    }
  }
  return (
    <>
      {showCurrency && '$'}
      <CountUp
        isCounting
        start={1}
        end={amount}
        duration={1.8}
        thousandsSeparator=','
        decimalPlaces={decimalPlaces}
      />
      {showInKMB && (isT ? 'T' : isB ? 'B' : isM ? 'M' : isK ? 'K' : '')}
    </>
  )
}

export const seoTitleTemplate = (title) => {
  return `${title} | Linqto | Private Investing Made Simple `
}

export const truncate = (str, charCount) => {
  if (str && str.length > charCount) {
    const trimmedString = str.substr(0, charCount)
    return (
      trimmedString.substr(
        0,
        Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
      ) + '...'
    )
  } else {
    return str
  }
}

/**
 * Returns the appropriate tag style based on the promo flag
 * @param {string} type - promo flag type 
 * @returns - tailwind css style for tag
 */
export const tagStyle = (type = '') => {
  switch(type) {
  case 'TOP_SELLER': return 'text-[#3E1C96] border-[#9E77ED]'
  case 'COMING_SOON': return 'text-[#1849A9] border-[#53B1FD]'
  case 'FULLY_SUBSCRIBED': return 'border-gray2 text-gray3'
  default: return 'border-gray2 text-gray3'
  }
}

export const transformPromoFlag = (promoFlag) => {
  if (promoFlag === 'FULLY_SUBSCRIBED') {
    return 'Unavailable'
  }
  return toTitleCase(promoFlag.replace(/_/g, ' '))
}

export const getWebsiteURL = (url) => {
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    url = 'https://' + url
  }
  return url
}

export const removeHtmlTags = (html) => {
  return html?.replace(/<\/?p>/g, '')
}

/**
 * If exists, removes the localStorage for the
 * add funds link on the buy order page
 * @function removeBuyOrderRedirect
 */
export const removeBuyOrderRedirect = () => {
  if (localStorage?.getItem('from-buy-order-page')) localStorage?.removeItem('from-buy-order-page')
}

// check if sting is contains only white spaces
export const isWhiteSpace = (str) => !str?.replace(/\s/g, '').length

export const isURL = (str) => {
  const reg = new RegExp('((http?|https|ftp|file)://)?((W|w){3}.)?[a-zA-Z0-9]+\\.([a-zA-Z]{2,5})+')
  return reg.test(str)
}

export const maskSSN = (val) => {
  if (val?.length < 10 && val?.length > 3) {
    const spaceCount = 9 - val.length
    const formattedSSN = val + new Array(spaceCount).join(' ')
    const ssnWithDash = formattedSSN.slice(0, 3) + '-' + formattedSSN.slice(3, 5) + '-' + formattedSSN.slice(5, 10)
    // make sure we can delete without blocking by space or -
    let toSend = ssnWithDash.replace(/\s+/g, '')
    if (/-$/gm.test(toSend)) {
      toSend = toSend.replace(/-$/gm, '')
    }
    return toSend
  } else {
    return val
  }
}

export const parseSSN = (val) => {
  return val.match(/\d/g) ? val.match(/\d+/g).join('') : ''
}

// Convert a string to Title case
export const toTitleCase = (e) => {
  return e.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

// check if value is not a number or its a negative number
export const checkIfNotNumber = (val) => {
  return val === '' || val <= 0 || isNaN(val)
}

// validate email address
export const isValidEmail = (val) => {
  return val?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
}

// place order slider related logic
export const setMaxPriceOnTiers = (offers) => {
  for (let i = 0; i < offers.length; ++i) {
    for (let k = 0; k < offers[i].tiers.length; ++k) {
      offers[i].tiers[k].maxPurchase =
        offers[i].tiers[k].sharePrice * offers[i].shares
    }
  }
  return offers
}
/**
 * Gets the current tier based on the
 * amount and minPurchase price
 * @function pickTier
 * @param {Object} offer
 * @param {Number} amount
 * @returns - tier in appropriate range
 */
export const pickTier = (offer, amount) => {
  for (var i = offer.tiers.length - 1; i >= 0; --i) {
    if (amount >= offer.tiers[i].minPurchase) {
      return offer.tiers[i]
    }
  }
  return offer.tiers[0]
}

/**
 * Gets the first and next tier
 * used to calculate savings and amount
 * until next tier
 * @function getFirstAndNextTier
 * @param {Object} tier
 * @param {Object} offer
 * @returns - first and next tier
 */
const getFirstAndNextTier = (tier, offer) => {
  const tierIndex = offer.tiers.indexOf(tier)
  let firstTier
  let nextTier
  if (tierIndex > 0) {
    firstTier = offer.tiers[0]
  }
  if (tierIndex < offer.tiers.length - 1) {
    nextTier = offer.tiers[tierIndex + 1]
  }
  return {
    firstTier, nextTier
  }
}

/**
 * This is used for initial slider render in InvestDetail.js and when
 * changing the slider in OrderDetails.js
 * Takes company offeres and calculates amount
 * - adjusts amount and shares based on tier and nearest share
 * - calculates team apollo savings
 * @function getSliderValue
 * @param {Object} company
 * @param {Number} index
 * @returns - amount, shares, sharePrice, tiers, Team Apollo savings/total
 */
export const getSliderValue = (company, index) => {
  const offers = company.offers
  // Amount is calculated from slider mimimum
  let amount = company?.sliderMinimum + company?.sliderIncrement * index
  let shares = 0
  let newAmount = 0
  let tier
  let totalSavings
  let totalTillNextTier
  let isPreferred = false
  let tiers
  // Go through current offers for company - this is legacy, there currently is only one offer per company
  if (offers && offers.length > 0) {
    for (let i = 0; i < offers.length; ++i) {
      const offer = offers[i]
      // Get the current tier
      tier = pickTier(offer, amount)
      let useAmount = amount < tier.maxPurchase ? amount : tier.maxPurchase
      // If our amount isn't a whole number of shares, round amount up to the nearest share
      useAmount = Math.ceil(useAmount / tier.sharePrice) * tier.sharePrice
      const useShares = Math.round(useAmount / tier.sharePrice)
      // Adjust values based on rounding
      shares += useShares
      newAmount += useAmount
      amount -= useAmount
      // Get first and next tiers
      // Calculate savings and totalTillNextTier for team apollo users
      const { firstTier, nextTier } = getFirstAndNextTier(tier, offer)
      totalSavings = firstTier ? (firstTier.sharePrice - tier.sharePrice) * useShares : 0
      totalTillNextTier = nextTier ? (nextTier.minPurchase - useAmount) : 0
      isPreferred = tier.isPreferred
      tiers = offer.tiers
      if (amount <= 0) {
        break
      }
    }
  }
  // Round new amount, shares and calculate share price
  newAmount = Math.round(newAmount * 100) / 100
  shares = Math.round(shares * 100) / 100
  const sharePrice = newAmount / shares
  return { amount: newAmount, shares, sharePrice, totalSavings, totalTillNextTier, isPreferred, tiers }
}

export const getCostBasis = (shareLots, sharesToSell) => {
  let shares = sharesToSell
  let costBasis = 0
  for (const lot of shareLots) {
    const useShares = shares > lot.shareCount ? lot.shareCount : shares
    costBasis += lot.sharePrice * useShares
    shares -= useShares
    if (shares <= 0) {
      break
    }
  }
  return costBasis
}

export const getAverageSharePrice = (costBasis, shares) => costBasis / shares

export const getDevice = () => {
  const { innerWidth: width } = window
  if (width < 768) return 'Web-Mobile'
  else if (width >= 768 && width < 1024) return 'Tablet'
  else return 'Web-Desktop'
}

/**
 * @param {string} renderItem The item we want to render (shares, fees, cost basis, etc...)
 * @param {function} func The function we want to perform if we want to render an item
 * @param {boolean} isNegative Render a '-' if true (for negative numbers)
 * @returns function that properly formats totals
 */
export const renderTotalsOrDash = (renderItem, func, isNegative = false) => {
  if (renderItem) {
    return `${isNegative ? '-' : ''}${func}`
  } else {
    return '-'
  }
}

export const getDomain = (route, cms) => {
  // linqto.com app.linqto.com
  // beta.linqto.com app.beta.linqto.com
  const domainArr = window.location.host.split('.')
  // meaning user is on app domian and need to go public
  if (domainArr[0] === 'app' && cms) {
    domainArr.shift()
    if (cms === 'help.linqto.com') {
      return 'https://help.linqto.com'
    }
    // console.log('going from private to public', `https://${domainArr.join('.')}${route}`)
    switch (window.location.host) {
    case 'app.linqto.com':
      return `https://www.linqto.com${route}`
    case 'app.beta.linqto.com':
      return `https://beta.linqto.com${route}`
    case 'app.alpha.linqto.com':
      return `https://alpha.linqto.com${route}`
    case 'app.growth.linqto.com':
      return `https://www.linqto.com${route}`
    case 'app.growth.linqto.io':
      return `https://www.linqto.com${route}`
    case 'app.team-ai.linqto.io':
      return `https://team-ai.linqto.io${route}`
    case 'app.trade.linqto.com':
      return `https://trade.linqto.com${route}`
    case 'app.trade.linqto.io':
      return `https://trade.linqto.io${route}`
    case 'app.dev.linqto.com':
      return `https://dev.linqto.com${route}`
    case 'app.dev.linqto.io':
      return `https://www.linqto.com${route}`
    default:
      return `https://alpha.linqto.com${route}`
    }
  }
  // console.log('staying at same domain')
  return `https://${window.location.host}${route}`
}

/**
 * @function getUserCountry - this is used only for flag services
 * @returns user country
 */
export const getUserCountry = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone.split('/')[0]
  const languageSet = navigator.language.split('-')
  const language = languageSet[languageSet.length - 1] || null
  const country = (timezone && language) ? `${timezone}-${language}` : null
  return country
}

export const getPreviousMonthTimestamp = (date) => {
  const previousDate = new Date(date)
  previousDate.setMonth(previousDate.getMonth() - 1)
  return previousDate.getTime()
}

export const getNextMonthTimestamp = (date) => {
  const nextDate = new Date(date)
  nextDate.setMonth(nextDate.getMonth() + 1)
  return nextDate.getTime()
}

 /**
   * Replaces placeholder strings in text with company name
   * 
   * @param {string} text - Text containing placeholders
   * @returns {string} Text with placeholders replaced
   */
 export const replacePlaceholders = (text, placeholder, placeholderValue) => {
  if (!text) return ''
  
  const regex = new RegExp(`{{${placeholder}}}`, 'g')
  return text.replace(regex, placeholderValue || '')
}

export * from './Mixpanel'
export * from './Contentful'
export * from './UTMTracking'
export * from './Braze'
export * from './Fullstory'
export * from './LaunchDarkly'