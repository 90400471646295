import { initializeApp } from 'firebase/app'
import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config'
import { getProjectConfig, getRemoteConfigSettings } from './firebase.config.js'

/**
 * https://firebase.google.com/docs/web/setup#add-sdk-and-initialize
 * A Firebase App is a container-like object that stores common configuration and shares authentication across Firebase services. After you initialize a Firebase App object in your code, you can add and start using Firebase services.
 * @returns {ConfigSource}
 */
function FirebaseRemoteConfig() {
  const firebaseConfig = getProjectConfig()
  const app = initializeApp(firebaseConfig)
  this.app = app
  this.rmConfig = getRemoteConfig(app)
  const rmConfigSettings = getRemoteConfigSettings()
  this.rmConfig.settings = {
    ...rmConfigSettings
  }
  return this
}

export default FirebaseRemoteConfig

FirebaseRemoteConfig.prototype = {
  ...FirebaseRemoteConfig.prototype,
  /**
   * Get the value for the config
   */
  getValue: async function (key) {
    return new Promise(resolve => {
      return resolve(getValue(this.rmConfig, key).asString())
    })
  },
  fetchAndActivate: async function () {
    return fetchAndActivate(this.rmConfig)
  }
}
