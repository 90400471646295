import { Modal, Button } from 'components'
import { useEffect } from 'react'
import { Mixpanel, Fullstory } from 'utils'

const ReferralError = ({ referralErrorMessage, closeModal }) => {
  useEffect(() => {
    if (referralErrorMessage) {
      Mixpanel.track('Referral Error', { 'Error Type': referralErrorMessage?.message })
      Fullstory.track('Referral Error', { error_type: referralErrorMessage?.message })
    }
  }, [referralErrorMessage])

  return (
    <Modal innerStyle='signup-error' modalHeader={referralErrorMessage?.title} crossToClose hideModal={closeModal}>
      <div className='items-center-justify-center'>
        <p className='medium_1 centered'>
          {referralErrorMessage?.message}
        </p>
      </div>
      <div className='inline-flex justify-center mt-6 mb-6'>
        <Button onClick={closeModal} data-testid='referralErrorBtn'>
          {referralErrorMessage?.actionLabel}
        </Button>
      </div>
      <span className='text-gray4 medium_1'>
        Need help?&nbsp;
        <Button className='inline-text-link' onClick={() => history.push('/contact')}>
          Contact Us
        </Button>
      </span>
    </Modal>
  )
}

export default ReferralError
