class User {
  userId
  firstName
  lastName
  email
  mfaRequired
  isDebugAdmin
  kycRequired
  redirectUrl
  amountUntilApollo
  isInPreferredCustomerPromptRange
  isImpersonating
  isPreferredCustomer
  canImpersonate
  riaActionRequired
  cashPaymentEnabled

  constructor(data) {
    Object.assign(this, data);
  }
  getEmail(){
    return this.email;
  }

  getFirstName(){
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getUserId() {
    return this.userId;
  }
}

export default User;