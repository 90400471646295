import { isReactLink } from 'utils/linkHelper'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const NavigationLink = ({ item, onClick, onLogout, className}) => {
  const { pathname } = useLocation()
  const { appLink, href } = isReactLink(item?.fields?.linkUrl)
  const isExternal = item?.fields?.isExternal || false
  const id = item?.sys?.id
  const title = item?.fields?.title
  const isSignOut = title === 'Sign Out'
  const isActive = (href === pathname  && item?.fields?.parent === 'None')|| false // make sure style is applied to top level nav items
  const isSecondayItem = item?.fields?.secondaryItem || false
  const itemStyle = item?.fields?.style || ''
  
  if (isSignOut) {
    return (
      <div
        key={`nav-${id}`}
        className={`${className} dropdown cobalt`}
        onClick={onLogout}
        role='button'
        tabIndex={0}
        data-testid='logout-button'
        aria-label={`Navigate to ${title}`}
      >
        {title}
      </div>
    )
  }
  
  if (isExternal || !appLink) {
    return (
      <a
        key={`nav-${id}`}
        href={href}
        onClick={onClick}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noreferrer noopener' : undefined}
        className={`${className} ${itemStyle === 'Button' ? 'nav-button' : ''} ${isActive ? 'active' : ''}`}
      >
        {title}
      </a>
    )
  } else if (appLink) {
    return (
      <NavLink
        key={`nav-${id}`}
        to={href}
        onClick={onClick}
        className={`${className}  ${itemStyle === 'Button' ? 'nav-button' : ''} ${isActive ? 'active' : ''}`}
      >
        {title}
        {isSecondayItem && <div className='nav-secondary-item'>{item?.fields?.secondaryItem}</div>}
      </NavLink>
    )
  }
}

export default NavigationLink