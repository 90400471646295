export const ENV_PROD = 'PROD'
export const ENV_BETA = 'BETA'
export const ENV_DEV = 'DEV'

const ENV_DOMAINS = {
  PROD: ['www.linqto.com', 'app.linqto.com'],
  BETA: ['beta'],
  DEV: ['growth', 'trade', 'dev']
}

function getEnv() {
  if (ENV_DOMAINS.PROD.some(domain => window.location.href.includes(domain))) {
    return ENV_PROD
  } else if (ENV_DOMAINS.BETA.some(domain => window.location.href.includes(domain))) {
    return ENV_BETA
  }
  return ENV_DEV
}

/**
 * Some utilities shouldn't run in local, so we check, to save on costs.
 */
function isLocal() {
  return window.location.href.includes('dev') || window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1')
}

function isProd() {
  return getEnv() === ENV_PROD
}


export default {
  getEnv,
  isLocal,
  isProd
}