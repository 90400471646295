const InvestDetailOrderPill = ({ active, amount, onClick }) => {
  return (
    <span
      onClick={onClick}
      role='button'
      aria-label='Investment Amount'
      tabIndex={0}
      className={`${
        active ? 'active text-accessibleIndigo' : ''
      } invest-order-pill text-gray5 flex body_2 h-10 items-center justify-center	cursor-pointer w-[93px] bg-gray1 max-w-full	rounded-full p-4`}
    >
      {amount}
    </span>
  )
}

export default InvestDetailOrderPill
